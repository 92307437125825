import axios from 'axios'
import {LoginResponse, UserResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const VALIDATE_PASSWORD_CODE_URL = `${API_URL}/auth/validate-password-code`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const REQUEST_PASSWORD_CODE_URL = `${API_URL}/mails/send-reset-password-code`

export function login(email: string, password: string) {
  return axios.post<LoginResponse>(LOGIN_URL, {
    email,
    password,
  })
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestCode(email: string) {
  return axios.post(REQUEST_PASSWORD_CODE_URL, {
    email,
  })
}

export function resetPassword(email: string, password: string, confirmPassword: string, accessToken: string) {
  return axios.post(RESET_PASSWORD_URL, {
    email,
    password,
    confirmPassword,
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function validatePassword(email: string, code: string) {
  return axios.post(VALIDATE_PASSWORD_CODE_URL, {
    email,
    code,
  })
}

export function getUserByToken() {
  return axios.get<UserResponse>(GET_USER_BY_ACCESSTOKEN_URL)
}
