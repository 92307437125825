import classnames from 'classnames';
import {NavItem, NavLink} from 'reactstrap';
import './index.scss';

interface ITabLink {
  index: string;
  customActiveTab: string;
  label: string;
  toggleCustom(tab: string): void;
}

const TabLink: React.FC<ITabLink> = ({label, index, customActiveTab, toggleCustom}) => {
  return (
    <NavItem>
      <NavLink
        style={{cursor: 'pointer'}}
        className={classnames({
          active: customActiveTab === index,
        })}
        onClick={() => {
          toggleCustom(index);
        }}
      >
        {label}
      </NavLink>
    </NavItem>
  );
};

export default TabLink;
