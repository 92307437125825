import {useIntl} from 'react-intl'
import {KTCardBody, KTIcon} from '../../../../../../_metronic/helpers'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {useForgotPasswordRequest} from '../../../../auth/components/CodeVerification/hooks/useForgotPasswordRequest'
import {toast} from 'react-toastify'
import {requestCode} from '../../../../auth/core/_requests'
const UsersDetails = (userData: any) => {
  const user = userData.userData
  const intl = useIntl()
  const {sendForgotPasswordRequest} = useForgotPasswordRequest()
  const handleForgotPassword = async () => {
    try {
      const {data} = await requestCode(user?.email)
      toast.success(data?.message)
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }
  return (
    <div className='table-responsive'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                {user?.picture?.url ? (
                  <img src={user?.picture?.url} alt='avatar' />
                ) : (
                  <span className='symbol-label bg-light fw-bold fs-1'>
                    {user?.first_name?.charAt(0).toUpperCase()}
                  </span>
                )}
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between h-100 align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column h-100 gap-4 w-100'>
                  {/* begin: area user info */}
                  <div className='d-flex w-100 justify-items-between flex-wrap'>
                    <div className='flex-grow-1 d-flex flex-column justify-content-between'>
                      <div className='fw-bold fs-6 mb-4 pe-2 text-gray-400 align-items-center'>
                        <div className='custom-name-log'>
                          {user?.first_name} {user?.last_name}
                        </div>
                        <div className={`badge badge-light fw-bolder color-log-details`}>
                          <KTIcon
                            iconName='profile-circle'
                            iconType='duotone'
                            className='fs-4 me-1'
                          />

                          {user?.departments?.name}
                        </div>
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 text-gray-400 align-items-center'>
                        <div className={`badge badge-light fw-bolder color-log-details`}>
                          <KTIcon iconName='sms' iconType='duotone' className='fs-4 me-1' />
                          {user?.email}
                        </div>
                      </div>
                      {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 text-gray-400 align-items-center'>
                      <div className={`badge badge-light fw-bolder color-log-details`}>
                      <KTIcon iconName='time' iconType='duotone' className='fs-4 me-1' />
                        {moment().format('HH:mm:ss')} - {moment().format('HH:mm:ss')} 
                        </div>
                      </div> */}
                    </div>
                    {/* end: left area user info */}
                    {/* begin: area labels */}
                    <div className='d-flex flex-column align-items-end'>
                      <div className='fw-semibold fs-6 mb-4 pe-2 text-gray-400 align-items-end d-flex gap-2 custom-button-log-details custom-badge'>
                        {user?.isTemporaryPass ? (
                          <div className={`badge badge-success fw-bolder custom-status-log`}>
                            <span>
                              {intl.formatMessage({id: 'USERS.SUBSCRIPTION.STATUS.LABEL'})}:{' '}
                              {intl.formatMessage({id: 'USERS.SUBSCRIPTION.STATUS.INVITED'})}
                            </span>
                          </div>
                        ) : (
                          <div className={`badge badge-success fw-bolder custom-status-log`}>
                            <span>
                              {intl.formatMessage({id: 'USERS.SUBSCRIPTION.STATUS.LABEL'})}:{' '}
                              {intl.formatMessage({id: 'USERS.SUBSCRIPTION.STATUS.ACTIVE'})}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='fw-semibold fs-6 mb-4 pe-2 text-gray-400 align-items-center d-flex gap-2'>
                        {user?.isTemporaryPass ? (
                          <Link
                            to='#'
                            onClick={(e) => {
                              e.preventDefault()
                              handleForgotPassword()
                            }}
                            style={{
                              marginRight: '10px',
                              color: '#3E97FF',
                              fontSize: '12px',
                              fontFamily: 'Hind',
                              alignContent: 'center',
                              fontWeight: 500,
                              lineHeight: '22.43px',
                              wordWrap: 'break-word',
                            }}
                          >
                            {intl.formatMessage({id: 'USERS.DETAILS.RESENT.LABEL'})}
                          </Link>
                        ) : null}
                      </div>
                    </div>
                    {/* end: right area labels */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UsersDetails}
