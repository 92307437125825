import React, {useState} from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap'
import {RatingInputProps} from './types'

const RatingInput: React.FC<RatingInputProps> = ({fieldProps, errors, touched}) => {
  const ratings = [1, 2, 3, 4, 5]

  return (
    <>
      <ButtonGroup onBlur={fieldProps.onBlur}>
        {ratings.map((rate, idx) => (
          <ToggleButton
            id={'rating-input-' + idx}
            key={idx}
            style={{border: '1px solid #d3d3d3'}}
            type='radio'
            variant='secondary'
            name={fieldProps.name}
            value={rate}
            checked={Number(fieldProps.value) === rate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // Convert the value to a number before passing it to Formik's onChange
              fieldProps.onChange({
                ...e,
                target: {
                  ...e.target,
                  name: e.target.name,
                  value: Number(e.target.value),
                },
              })
            }}
          >
            {rate}
          </ToggleButton>
        ))}
      </ButtonGroup>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <span role='alert' className='text-danger'>
            {errors}
          </span>
        </div>
      )}
    </>
  )
}

export default RatingInput
