import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { useTranslate } from '../function/translate';


const showAlert = ({
  title,
  buttonText,
  icon = 'success',
  classDetail = 'btn btn-success-alert alert-button',
}: {
  title: string;
  buttonText: string;
  icon?: SweetAlertOptions['icon'];
  classDetail?: string;
}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title: `<span class="alert-title ">${title}</span>`,
    icon: icon,
    buttonsStyling: false,
    showCancelButton: false,
    confirmButtonText: buttonText,
    customClass: {
      confirmButton: classDetail,
    },
  });
};

export const DeleteAlert = async (obj) => {  
  const result = await Swal.fire(obj);

  return result.isConfirmed;
}

export const DeleteCompleted = (title1, title2)=>{
  Swal.fire(
    title1,
    title2,    
    "success"
  )
}

export default showAlert;
