import * as Yup from 'yup'
import {emailRegex} from '../../../../../../constants/constants'

export const dataSchema = Yup.object().shape({
  first_name: Yup.string().required('AUTH.VALIDATION.REQUIRED_FIELD'),
  last_name: Yup.string().required('AUTH.VALIDATION.REQUIRED_FIELD'),
  email: Yup.string()
    .email('AUTH.VALIDATION.INVALID_EMAIL')
    .matches(emailRegex, 'AUTH.VALIDATION.INVALID_EMAIL')
    .min(3, 'AUTH.VALIDATION.MIN_LENGTH')
    .max(50, 'AUTH.VALIDATION.MAX_LENGTH')
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
  department_id: Yup.string().required('AUTH.VALIDATION.REQUIRED_FIELD'),
})

export default dataSchema
