import axios from 'axios';
import {toast} from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_TODAY_TIMELOG = `${API_URL}/timelog/all`;
export const TIMELOG_CLOCK_IN = `${API_URL}/timelog/clock-in`;
export const TIMELOG_CLOCK_OUT = `${API_URL}/timelog/clock-out`;
export const TIMELOG_START_LUNCH = `${API_URL}/timelog/start-lunch`;
export const TIMELOG_END_LUNCH = `${API_URL}/timelog/end-lunch`;

export function getTodayStatus(userId?: string | number) {
  return axios
    .get(`${GET_TODAY_TIMELOG}/latest?user=${userId}`)
    .catch((error) => toast.error(error?.response?.data?.message || 'Something went wrong'));
}

export function setClockIn(user?: string | number) {
  return axios.post(`${TIMELOG_CLOCK_IN}`, {user});
}

export function setClockOut(user?: string | number) {
  return axios.post(`${TIMELOG_CLOCK_OUT}`, {user});
}

export function setStartLunch(user?: string | number) {
  return axios.post(`${TIMELOG_START_LUNCH}`, {user});
}

export function setEndLunch(user?: string | number) {
  return axios.post(`${TIMELOG_END_LUNCH}`, {user});
}
