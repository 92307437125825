import {ClientManagersListSearchComponent} from './ClientManagersListSearchComponent';
import {ClientManagersListToolbar} from './ClientManagersListToolbar';

const ClientManagersListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ClientManagersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<ClientManagersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export {ClientManagersListHeader};
