import {useMemo} from 'react';
import {ColumnInstance, Row, useTable} from 'react-table';
import {KTCardBody} from '../../../../../../_metronic/helpers';
import {useTranslate} from '../../../../../function/translate';
import {UsersListLoading} from '../components/loading/UsersListLoading';
import {UsersListPagination} from '../components/pagination/UsersListPagination';
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProviderClientFeedback';
import {ClientFeedback} from '../core/_models';
import {CustomHeaderColumn} from './columns/CustomHeaderColumnClientFeedback';
import {CustomRow} from './columns/CustomRowClientFeedback';
import {clientFeedbackColumns} from './columns/_columnsClientFeedback';

const ClientFeedbackTable = () => {
  const clientFeedbacks = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => clientFeedbacks, [clientFeedbacks]);
  const columns = useMemo(() => clientFeedbackColumns, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });
  const translate = useTranslate();
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_clientFeedbacks'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ClientFeedback>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ClientFeedback>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {translate('DASHBOARD-TABLE-NOT-MACH')}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};

export {ClientFeedbackTable};
