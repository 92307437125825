// @ts-nocheck
import {Column} from 'react-table'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {User} from '../../core/_models'
import {UserTimeLogDate} from './UserTimeLogDate'
import {UserTimeLogDateTime} from './UserTimeLogDateTime'
import {UserTimeLogHour} from './UserTimeLogHour'
import {UserInfoCell} from './UserInfoCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='DASHBOARD-ADMIN-CLOCK-IN' className='min-w-125px custom-header-title' />
    ),
    id: 'clockIn',
    Cell: ({ ...props }) => {
      return (
        <div>
          {props.data[props.row.index]?.clockIn ? (
            <UserTimeLogDateTime datetime={props.data[props.row.index]?.clockIn} />
          ) : (
            <div style={{ paddingLeft: '20px' }}>
              ___
            </div>
          )}
        </div>
      );
    }    
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='DASHBOARD-ADMIN-CLOCK-OUT' className='min-w-125px custom-header-title' />
    ),
    id: 'clockOut',
    Cell: ({ ...props }) => {
      return (
        <div>
          {props.data[props.row.index]?.clockOut ? (
            <UserTimeLogDateTime datetime={props.data[props.row.index]?.clockOut} />
          ) : (
            <div style={{ paddingLeft: '20px' }}>
              ___
            </div>
          )}
        </div>
      );
    }    
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='DASHBOARD-ADMIN-LUNCH' className='min-w-125px custom-header-title' />
    ),
    id: 'lunch',
    Cell: ({ ...props }) => {
      return (
        <div>
          {props.data[props.row.index]?.startLunch ? (
            <UserTimeLogHour time={props.data[props.row.index]?.startLunch} />
          ) : (
            <div style={{ padding: '0 20px', display: 'inline-block' }}></div>
          )}
          <div style={{ padding: '0 20px', display: 'inline-block' }}>
            -
          </div>
          {props.data[props.row.index]?.endLunch ? (
            <UserTimeLogHour time={props.data[props.row.index]?.endLunch} />
          ) : (
            <div style={{ padding: '0 20px', display: 'inline-block' }}></div>
          )}
        </div>
      );
    }    
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='DASHBOARD-ADMIN-ACTIONS' className='text-end min-w-100px custom-header-title' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
