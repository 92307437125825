import { useEffect, useState } from "react"
import { dataSchema, initialValues } from "../constants"
import { useFormik } from "formik"
import { resetPassword } from "../../../core/_requests"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

export const useResetPassword = (initialEmail, token) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [email, setEmail] = useState<string>(initialEmail)
  const [accessToken, setAccessToken] = useState<string>(initialEmail)
  const [errorMsj, setErrorMsj] = useState(false)

  useEffect(() => {
    if (initialEmail && token) {
      const email_qp = initialEmail as string
      const token_qp = token as string
      setEmail(email_qp)
      setAccessToken(token_qp)
    } else {
      navigate('/auth')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid } = useFormik({
    initialValues,
    validationSchema: dataSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const { data }= await resetPassword(email, values.password, values.repeatPassword, accessToken)
        setHasErrors(false)
        setLoading(false)
        toast.success(data.message)
        navigate(`/auth/change-password`, { state: {} })
        navigate(`/auth/login`, { state: {} })
        
      } catch (error: any) {
        setErrorMsj(error?.response?.data?.message);
        toast.error(error?.response?.data?.message)
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('AUTH.AXIOS:ERROR')
      }
    },
  })

  return {
    loading,
    hasErrors,
    handleSubmit,
    errorMsj,
    getFieldProps,
    errors,
    touched,
    isSubmitting,
    setHasErrors,
    isValid
  }
}
