import * as Yup from 'yup'
import { PageLink } from "../../../../_metronic/layout/core";
import { regexPassword } from '../../../constants/constants';

export const changePasswordCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const initialValues = {
  newPassword: '',
  confirmNewPassword: '',
}

export const changePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      regexPassword,
      'AUTH.CHANGE.LABEL.VALIDATION.PASSWORD'
    )
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
    confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ""], 'AUTH.INPUT.REPEAT_PASSWORD.VALIDATION.MATCH')
    .required('AUTH.INPUT.REPEAT_PASSWORD.VALIDATION'),
});

