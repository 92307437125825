/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import { KTIcon } from '../../../../../../_metronic/helpers'
import 'react-datepicker/dist/react-datepicker.css';
import CustomDataPicker from '../../../../../components/CustomDataPicker';
import { useTranslate } from '../../../../../function/translate';
import { useFindModal } from './hooks/findModalHook';


type Props = {
  show: boolean
  handleClose: () => void
}

//const modalsRoot = document.getElementById('root-modals') || document.body

const FindAppModal = ({show, handleClose}: Props) => {  

  const submit = () => {
    window.location.reload()
  }
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(startDate);
  const translate = useTranslate();
  const {
    errors,
    getFieldProps,
    handleSubmit,
    hasErrors,
    errorMsj,
    setHasErrors,
    loading,
    touched,
    closeModal,
    setCloseModal
  } = useFindModal();

  let {
    values
  } = useFindModal();

  // useEffect(() => {
  //   values.date1 = new Date().toString();
  //   values.date2 = new Date().toString();
  //   }, [])
  useEffect(() => {
    if(closeModal){
      setCloseModal(false);
      handleClose()
    }
      
  }, [closeModal])

  const handleDateChanges = (date) => {
    setStartDate(date);
    values.date1 = date;
  };
  const handleDateChangesDate2 = (date) => {
    setStartDate2(date);
    values.date2 = date;
  };

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-400px align-items-center'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='modal-header'>
        <h2>{translate('DATE-TITLE')}</h2>
        
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-2'>

      <div className='fv-row mb-3 '>
        <CustomDataPicker
          name='date1'
          label= {translate('DATE-FROM')}
          placeholder='calendar'
          icon='calendar'
          autoComplete='off'
          className= 'form-control d-flex align-items-center input-style'
          errors={errors?.date1 ? translate(errors.date1) : ''}
          touched={touched?.date1}   
          selected = {startDate}
          startDate={startDate}
          endDate={startDate2}
          maxDate={startDate2}
          onChange={handleDateChanges} 
             
        />
      </div>
      <div className='fv-row mb-3 '>
        <CustomDataPicker
          name='date2'
          icon='calendar'
          label= {translate('DATE-TO')}
          placeholder='calendar'
          autoComplete='off'
          className= 'form-control d-flex align-items-center input-style'
          errors={errors?.date2 ? translate(errors.date2) : ''}
          touched={touched?.date2}   
          selected = {startDate2}
          startDate={startDate}
          endDate={startDate2}
          minDate={startDate}
          onChange={handleDateChangesDate2} 
             
        />
      </div>

      
      
     
      </div>

      <div className="modal-footer">
        <div className='custom-button'>
        <button type='submit' className="btn btn-success">{translate('DATE-FILTER')}</button>
        </div>
        <div className='container-button-light'>
        <button type="button" className="btn btn-light" onClick={handleClose} data-bs-dismiss="kt_modal_create_app">{translate('DATE-CANCEL')}</button>
        </div>   
                
                
      </div>
      </form>
    </Modal>
  )
}

export {FindAppModal}