import { useState } from "react"
import { forgotPasswordSchema, initialValues } from "../constants"
import { useFormik } from "formik"
import { requestCode } from "../../../core/_requests"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

export const useForgotPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMsj, setErrorMsj] = useState("") 
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid } = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const { data } = await requestCode(values.email)
        setHasErrors(false)
        setLoading(false)
        toast.success(data.message)
        const dataToSend = {
          email: values.email,
        };
        navigate(`/auth/forgot-password/email-verification`, { state: dataToSend })
        localStorage.setItem('countdown', "0");
        localStorage.setItem('isResendClicked', "false");        
        
      } catch (error: any) {
        setErrorMsj(error?.response?.data?.message);
        toast.error(error?.response?.data?.message)
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('AUTH.AXIOS:ERROR')
      }
    },
  })

  return {
    loading,
    hasErrors,
    handleSubmit,
    errorMsj,
    getFieldProps,
    errors,
    touched,
    isSubmitting,
    setHasErrors,
    isValid
  }
}
