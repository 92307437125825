/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
import { ID, KTIcon, QUERIES, initialQueryState } from "../../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteUserData, enableUserData, suspendUserData } from "../../core/_requests";
import { toast } from "react-toastify";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useTranslate } from "../../../../../../function/translate";
import { Link } from "react-router-dom";
import { DeleteAlert, DeleteCompleted } from "../../../../../../hooks/customAlert";

type Props = {
  id: ID;
  userId: ID;
};

const UserActionsCell: FC<Props> = ({ id, userId }) => {
  const { setItemIdForUpdate, onSelect, selected } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };
  const translate = useTranslate();

  const deleteItem = useMutation(() => deleteUserData(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message || "");
      selected.includes(id) && onSelect(id);
      DeleteCompleted(translate("ALERT-DELETE"), translate("ALERT-DELETE2"));
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    },
    onError: (error: any) => {
      toast.success(error?.message || "");
    },
  });

  const suspendItem = useMutation(() => suspendUserData(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message || "");
      selected.includes(id) && onSelect(id);
      DeleteCompleted(translate("ALERT-DELETE4"), translate("ALERT-DELETE2"));
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    },
    onError: (error: any) => {
      toast.success(error?.message || "");
    },
  });

  const enableItem = useMutation(() => enableUserData(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message || "");
      selected.includes(id) && onSelect(id);
      DeleteCompleted(translate("ALERT-DELETE3"), translate("ALERT-ENABLE"));
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    },
    onError: (error: any) => {
      toast.success(error?.message || "");
    },
  });

  const enableF = async () => {
    const confirmed = await DeleteAlert({
      title: translate("ALERT-TITLE"),
      text: translate("ALERT-TEXT"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translate("ALERT-CONFIRM-ACTIVE"),
    });
    if (confirmed) {
      enableItem.mutateAsync();
    }
  };

  const archiveF = async () => {
    const confirmed = await DeleteAlert({
      title: translate("ALERT-TITLE"),
      text: translate("ALERT-TEXT"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translate("ALERT-CONFIRM"),
    });
    if (confirmed) {
      deleteItem.mutateAsync();
    }
  };

  const suspendF = async () => {
    const confirmed = await DeleteAlert({
      title: translate("ALERT-TITLE"),
      text: translate("ALERT-TEXT"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translate("ALERT-CONFIRM-SUSPEND"),
    });
    if (confirmed) {
      suspendItem.mutateAsync();
    }
  };

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm custom-button-table-action'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {translate("TABLE-ACTIONS")}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3 submenu-custom'>
          <Link className='menu-link px-3' to={`/users/${id}/history`}>
            {translate("TABLE-MENU-VIEW")}
          </Link>
        </div>
        {/* begin::Menu item */}
        <div className='menu-item px-3 submenu-custom'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {translate("USERS.ADD-BUTTON-EDIT")}
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link submenu-custom text-success px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => {
              enableF();
            }}
          >
            {translate("USERS.ADD-BUTTON-ENABLE")}
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link submenu-custom text-warning px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => {
              archiveF();
            }}
          >
            {translate("USERS.ADD-BUTTON-DELETE")}
          </a>
        </div>
        {/* end::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link submenu-custom text-danger px-3'
            onClick={async () => {
              suspendF();
            }}
          >
            {translate("USERS.ADD-BUTTON-SUSPEND")}
          </a>
        </div>
      </div>
      {/* end::Menu */}
    </>
  );
};

export { UserActionsCell };
