import React from 'react';
import {CustomSelectsProps} from './types';
import clsx from 'clsx';
import './CustomSelect.css';
import {KTIcon} from '../../../_metronic/helpers';

const CustomSelect: React.FC<CustomSelectsProps> = ({
  name,
  errors,
  fieldProps,
  touched,
  autoComplete,
  placeholder,
  icon,
  label,
  type = 'text',
  onTextChange,
  numIconSpansPass = 6,
  numIconSpansInput = 6,
  classNameLabel = 'form-label fs-6 fw-bolder text-dark',
  className = '',
  style = {},
  options = [],
  valueSelected,
  onChange,
  classContainer = 'custom-select',
  plainText = 'custom-form-control-plaintext',
  disabled,
}) => {
  const [classPlainText, setClassPlainText] = React.useState(plainText);
  const onClick = () => {
    if (valueSelected !== '') {
      setClassPlainText('custom-form-control-plaintext2');
    }
  };

  return (
    <div className={classContainer}>
      {label && <label className={` ${classNameLabel}`}>{label}</label>}
      <div className={`${className} bg-custom-green`} style={style}>
        {icon && <KTIcon iconName={icon} iconType='duotone' className='fs-3 me-2' />}

        <select
          className={clsx(
            `bg-transparent w-100 form-control-plaintext p-0 w-custom-select ${classPlainText}`,
            {'is-invalid': touched && errors},
            {
              'is-valid': touched && !errors,
            }
          )}
          data-placeholder={placeholder}
          name={name}
          value={valueSelected}
          data-kt-select2='true'
          data-allow-clear='true'
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        >
          {/* disabled selected */}
          {options.map((option) =>
            option.value === '' ? (
              <option key={option.value} value={option.value} disabled>
                {option.option}
              </option>
            ) : (
              <option key={option.value} value={option.value}>
                {option.option}
              </option>
            )
          )}
        </select>
      </div>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <span role='alert' className='text-danger'>
            {errors}
          </span>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
