import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { changePasswordCrumbs } from './constants'
import CustomInput from '../../components/CustomInput'
import { Link } from 'react-router-dom'
import { useChangePassword } from './hooks/useChangePassword'
import CustomAlert from './../../components/CustomAlert/index';
import { useIntl } from 'react-intl'
import { customStyle } from '../../constants/styles'
import { KTCardBody } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import showAlert from '../../hooks/customAlert'

const ChangePasswordPage: FC = () => {
  const {
    errors,
    getFieldProps,
    handleSubmit,
    setHasErrors,
    hasErrors,
    isSubmitting,
    loading,
    touched,
    errorMessage,
  } = useChangePassword()
  const intl = useIntl()

  const closeError = () => {
    setHasErrors(undefined);
  };
  const { auth } = useAuth()

  return (
    <>
    {/*
      <PageTitle breadcrumbs={changePasswordCrumbs}>
        Change password
      </PageTitle>
      */}
      
    
      <div className='d-flex flex-column justify-content-center align-items-start'>
      {/* begin::Wrapper */}
      <div className='w-100 w-lg-700px p-10'>
      <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      
      {auth?.isTemporaryPass ? (
        <div className='text-start mb-10'>
          <div className='change-pass-h1'>
          <h1 >{intl.formatMessage({id: 'DASHBOARD-CHANGE-LABEL-PASS-TITLE'})}</h1>
          </div>
          
          <div className='base-label-change change-pass-label'> 
              {intl.formatMessage({id: 'DASHBOARD-CHANGE-LABEL-PASS-TITLE-2'})}    
          </div>
        </div>
      ) : 
      <div className='text-start mb-10'>
          <div className='change-pass-h1'>
          <h1 >{intl.formatMessage({id: 'DASHBOARD-CHANGE-LABEL-PASS-TITLE-b1'})}</h1>
          </div>
          
          <div className='base-label-change change-pass-label'> 
              {intl.formatMessage({id: 'DASHBOARD-CHANGE-LABEL-PASS-TITLE-b2'})}    
          </div>
        </div>}


      {/* begin::Title */}
      {hasErrors === true && (
        <CustomAlert errorMessage={errorMessage} onClose={closeError} />
      )}
      
      <div className="mb-7 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <CustomInput
            autoComplete='off'
            errors={errors?.newPassword ? intl.formatMessage({ id: errors.newPassword }) : ''}
            touched={touched.newPassword}
            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.NEW.PASSWORD' })}
            type='password'
            fieldProps={getFieldProps('password')}
            name='newPassword'
            icon="ki-duotone ki-lock-2"
            className= 'form-control d-flex align-items-center input-style'
            />
          </div>
          <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
        </div>
        <div className="base-style  change-validation-label" data-kt-translate="new-password-hint">
          {intl.formatMessage({id: 'AUTH.CHANGE:ERROR'})}
        </div>
      </div>
      <div className='fv-row mb-8'>
        <CustomInput
          autoComplete='off'
          errors={errors?.confirmNewPassword ? intl.formatMessage({ id: errors.confirmNewPassword }) : ''}
          touched={touched.confirmNewPassword}
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
          type='password'
          fieldProps={getFieldProps('repeatPassword')}
          name='confirmNewPassword'
          icon="ki-duotone ki-lock-2"
          className= 'form-control d-flex align-items-center input-style'
        />
      </div>

      <div className="mb-10 custom-container">
        <div className="custom-label">
        {intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.1'})}
        </div>
        <div className="custom-items">
          <div className="custom-item">
          <div className="custom-icon-container">
            <i className="bi bi-check2-circle"></i>
            </div>
            <div className="custom-gray-text">{intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.2'})} 
            </div>
          </div>
          <div className="custom-item">
            <div className="custom-icon-container">
            <i className="bi bi-check2-circle"></i>
            </div>
            <div className="custom-gray-text">
            {intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.3'})} 
            </div>
          </div>
          <div className="custom-item">
          <div className="custom-icon-container">
            <i className="bi bi-check2-circle"></i>
            </div>
            <div className="custom-gray-text">{intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.4'})} 
            </div>
          </div>
        </div>
      </div>

      
  
      <div className='d-grid mb-10 custom-button' >
        <button type='submit' id='kt_password_reset_submit' className='btn btn-success me-4 btn-xl custom-button' data-kt-translate="new-password-submit">
          <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON-SET-UP'})}</span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'GENERAL.PROGRESS'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div> 
      
      {/* end::Form group */}
    </form>
          </div>
          {/* end::Wrapper */}
        </div>
    
    </>
  )
}

export {ChangePasswordPage}
