import {FC} from 'react'

type Props = {
  user: any
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column custom-table-data'>
      <div className='text-gray-800  mb-1'>
        {user.name}
      </div>
      <span>{user.email}</span>
    </div>
  </div>

  
)

export {UserInfoCell}
