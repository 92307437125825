/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import {useIntl} from 'react-intl';
import CustomTextArea from '../../components/CustomTextArea';
import RatingInput from '../../components/RatingInput';
import {useFormFeedback} from './hooks/useFormFeedback';

export function FormFeedback() {
  const {
    errors,
    loading,
    touched,
    isSubmitting,
    isValid,
    formJustSent,
    alreadyFilled,
    user,
    evalDate,
    getFieldProps,
    handleSubmit,
  } = useFormFeedback();
  const intl = useIntl();

  if (loading || !user) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        {intl.formatMessage({id: 'AUTH.LOGIN.LOADING'})}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    );
  }

  if (alreadyFilled || formJustSent) {
    return (
      <div className='card card-custom gutter-b'>
        <div className='card-body d-flex align-items-center justify-content-between flex-wrap'>
          <div className='mr-2'>
            <h1 className='fw-bolder fs-2x text-dark mb-5'>
              {intl.formatMessage({id: formJustSent ? 'CLIENT_FEEDBACK.JUST-SENT' : 'CLIENT_FEEDBACK.ALREADY-SENT'})}
            </h1>
            <b>Name</b>: {user.first_name} {user.last_name}
            <br />
            <b>Date</b>: &nbsp;&nbsp;{moment(evalDate).format('MMMM YYYY')}
            <div className='d-flex h-80px flex-shrink-0 flex-center position-relative'>
              <i className='fa-solid fa-clipboard-check  text-success fs-4x fs-lg-4x'></i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
      {/* begin::Heading */}
      <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 my-0 flex-column justify-content-center pb-5'>
        {intl.formatMessage({id: 'CLIENT_FEEDBACK.TITLE'})}
      </h1>

      <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
            <i className='fa fa-square-poll-vertical text-primary fs-4x fs-lg-4x'></i>
          </div>
          <div className='ms-6'>
            <p className='list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0'>
              <span className='text-gray-700'>{intl.formatMessage({id: 'CLIENT_FEEDBACK.SUBTITLE'})}</span>
              <br className='mb-3' />
              <b>Name</b>: {user.first_name} {user.last_name}
              <br />
              <b>Date</b>: &nbsp;&nbsp;{moment(evalDate).format('MMMM YYYY')}
            </p>
          </div>
        </div>
      </div>
      {/* end::Heading */}

      {/* begin::Form group */}

      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          1. {intl.formatMessage({id: 'CLIENT_FEEDBACK.Q1'})}
        </label>

        <RatingInput
          name='question1'
          touched={touched.question1}
          errors={errors?.question1 ? intl.formatMessage({id: errors.question1}) : ''}
          fieldProps={getFieldProps('question1')}
        />
      </div>

      <div className='fv-row mb-3'>
        <CustomTextArea
          name='question2'
          label={`2. ${intl.formatMessage({id: 'CLIENT_FEEDBACK.Q2'})}`}
          touched={touched.question2}
          errors={errors?.question2 ? intl.formatMessage({id: errors.question2}) : ''}
          rows={5}
          fieldProps={getFieldProps('question2')}
          autoComplete='off'
          className='form-control d-flex align-items-center input-style'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}

      <div className='container-button d-grid mb-10 custom-button'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-success' disabled={isSubmitting || !isValid}>
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'AUTH.LOGIN.LOADING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
