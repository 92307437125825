import {Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { CodeVerification } from './components/CodeVerification'
import { ChangePassword } from './components/ChangePassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='forgot-password/email-verification' element={<CodeVerification />} />
      <Route path='change-password' element={<ChangePassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
  
)

export {AuthPage}
