import React, { useState } from 'react';
import { CustomInputsProps } from './types';
import clsx from 'clsx';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePicker.css';
import { KTIcon } from '../../../_metronic/helpers';

const CustomDataPicker: React.FC<CustomInputsProps> = ({
  name,
  errors,
  fieldProps,
  touched,
  autoComplete,
  placeholder,
  icon,
  label,
  type = 'text',
  numIconSpansPass = 6,
  numIconSpansInput = 6,
  classNameLabel = 'form-label fs-6 fw-bolder text-dark',
  className= 'form-control d-flex align-items-center ',
  style={},
  onChange,
  selected,
  startDate,
  endDate,
  maxDate,
  minDate,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  timeIntervals = null,
  dateFormat= 'MM/dd/yyyy',
  typeIcon ='duotone',
  iconClass='fs-3 me-2'
}) => {

  const toggleInputType = () => {
  }  
  const spansPass: any[] = [];
  for (let i = 1; i <= numIconSpansPass; i++) {
    spansPass.push(<span key={i} className={`path${i}`}></span>);
  }
  const spansInput: any[] = [];
  for (let i = 1; i <= numIconSpansInput; i++) {
    spansInput.push(<span key={i} className={`path${i}`}></span>);
  }

 

  return (
    <div className='custom-date-picker'>
      {label && (<label className={` ${classNameLabel}`}>{label}</label>)}
      <div className={`${className}`} style={style}>
        {icon && <KTIcon iconName={icon} iconType='duotone' className={iconClass} />}
        <DatePicker
          autoComplete = {autoComplete}
          placeholder={placeholder}
          {...fieldProps}
          className={clsx(
            'bg-transparent form-control-plaintext p-0 w-custom',
            {'is-invalid': touched && errors},
            {
              'is-valid': touched && !errors,
            }
          )}
          name= {name}
          onChange={onChange}
          selected = {selected}
          type={type}
          onDateChange = {onChange}
          startDate= {startDate}
          endDate= {endDate}
          showMonthDropdown
          showYearDropdown
          maxDate = {maxDate}
          minDate = {minDate}
          dropdownMode="select"
          showTimeSelect = {showTimeSelect}
          showTimeSelectOnly = {showTimeSelectOnly}
          timeIntervals = {timeIntervals}
          dateFormat= {dateFormat}
        />
        {false && (
          <i
            className={`ki-duotone ki-down fs-3 me-1 cursor-pointer`}
            onClick={toggleInputType}
            style={{ color: "#555555" }}
          >
            {spansPass}
          </i>
        )}
      </div>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <span role='alert' className='text-danger'>{errors}</span>
        </div>
      )}
    </div>
  )
}

export default CustomDataPicker
