import {useQuery} from 'react-query';
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers';
import {getClientManagerById} from '../core/_requests';
import {useListView} from '../core/ListViewProvider';
import {ClientManagerEditModalForm} from './ClientManagerEditModalForm';

const ClientManagerEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView();
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.CLIENT_MANAGERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getClientManagerById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
    }
  );

  if (!itemIdForUpdate) {
    return <ClientManagerEditModalForm isClientManagerLoading={isLoading} user={{id: undefined}} />;
  }

  if (!isLoading && !error && user) {
    return <ClientManagerEditModalForm isClientManagerLoading={isLoading} user={user} />;
  }

  return null;
};

export {ClientManagerEditModalFormWrapper};
