// @ts-nocheck
import moment from 'moment';
import {Column} from 'react-table';
import ExpandableLabel from '../../../../../../components/ExpandableLabel';
import {ClientFeedback} from '../../core/_models';
import {UserCustomHeader} from './UserCustomHeader';

const clientFeedbackColumns: ReadonlyArray<Column<ClientFeedback>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='USERS.TABLE-HEADER-DATE'
        className='min-w-125px custom-header-title'
      />
    ),
    id: 'evaluation_date',
    Cell: ({row}) => {
      return (
        <div>
          <div className='d-flex align-items-center'>
            {/* begin:: Avatar */}
            <div className='d-flex flex-column custom-table-data'>
              {moment(row.original.evaluation_date).format('MMMM YYYY')}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='USERS.TABLE-HEADER-MANAGER'
        className='min-w-125px custom-header-title'
      />
    ),
    id: 'client_manager',
    Cell: ({row}) => {
      return (
        <div>
          {row.original.client_manager ? (
            row.original.client_manager.first_name + ' ' + row.original.client_manager.last_name
          ) : (
            <div style={{paddingLeft: '20px'}}>___</div>
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='USERS.TABLE-HEADER-QUESTION1'
        className='min-w-125px custom-header-title'
        tooltip='CLIENT_FEEDBACK.Q1'
      />
    ),
    id: 'question1',
    Cell: ({row}) => {
      return row.original.question1;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='USERS.TABLE-HEADER-QUESTION2'
        className='min-w-125px custom-header-title'
        tooltip='CLIENT_FEEDBACK.Q2'
      />
    ),
    id: 'question2',
    Cell: ({row}) => {
      return (
        <div>
          {row.original.question2 ? (
            <ExpandableLabel>{row.original.question2}</ExpandableLabel>
          ) : (
            <div style={{paddingLeft: '20px'}}>___</div>
          )}
        </div>
      );
    },
  },
];

export {clientFeedbackColumns};
