import { useEffect, useState } from "react";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { getAllDepartmentsFetch } from "../../../../client-managers/client-managers-list/user-edit-modal/hooks/getDepartments";
import { setDepartmentSelectOptions } from "../../../../../../function/generalHelper";
import { useTranslate } from "../../../../../../function/translate";
import CustomSelect from "../../../../../../components/CustomSelect";

type DepartmentOption = {
  value: string;
  option: string;
};

function AdminUserFilters() {
  const { updateState } = useQueryRequest();
  const [departments, setDepartments] = useState<DepartmentOption[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [status, setStatus] = useState("");

  useEffect(() => {}, [status]);

  const fetchData = async () => {
    try {
      const { data } = await getAllDepartmentsFetch();
      if (data.length > 0) {
        const optionsSelect = setDepartmentSelectOptions(data);
        setDepartments(optionsSelect);
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const parsedDepartment = Number(selectedDepartment);
    const parsedStatus = Number(status);
    if (parsedDepartment === 0 && parsedStatus === 0) {
      return updateState({ filter: undefined });
    }
    if (parsedDepartment !== 0 && parsedStatus === 0) {
      return updateState({ filter: { department_id: selectedDepartment } });
    }
    if (parsedDepartment === 0 && parsedStatus !== 0) {
      return updateState({ filter: { status_id: status } });
    } else {
      return updateState({ filter: { status_id: status, department_id: selectedDepartment } });
    }
  }, [selectedDepartment, status]);

  const translate = useTranslate();
  return (
    <>
      <div className='card-toolbar'>
        <div className='d-flex align-items-center position-relative my-1'>
          <CustomSelect
            name='department'
            className='w-250px form-control d-flex align-items-center input-style'
            icon='files-tablet'
            placeholder='select'
            options={[
              { value: "", option: translate("USERS.ADD-USER-DEPARTMENT") },
              { value: 0, option: translate("ALL") },
              ...departments,
            ]}
            classContainer='custom-select-smaller d-flex justify-content-center'
            onChange={(e: any) => setSelectedDepartment(e.target.value)}
          />
        </div>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex align-items-center position-relative my-1'>
          <CustomSelect
            name='status'
            className='w-250px form-control d-flex align-items-center input-style'
            icon='files-tablet'
            placeholder='select'
            options={[
              { value: 0, option: "All" },
              { value: 1, option: "Active" },
              { value: 2, option: "Archived" },
              { value: 3, option: "Suspended" },
            ]}
            classContainer='custom-select-smaller d-flex justify-content-center'
            onChange={(e: any) => setStatus(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

export default AdminUserFilters;
