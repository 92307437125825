import {useEffect, useRef, useState} from 'react';
import {KTIcon, initialQueryState} from '../../../../../../../_metronic/helpers';
import {useTranslate} from '../../../../../../function/translate';
import {useListView} from '../../core/ListViewProvider';
import {useQueryRequest} from '../../core/QueryRequestProvider';
import {useQueryResponse} from '../../core/QueryResponseProvider';
import {UsersListFilter} from './UsersListFilter';
import {FindAppModal} from '../../findModal/findAppModal';
import {exportToExcel} from '../../../../../../function/excelHelper';
import {getExportTimeLog} from '../../core/_requests';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {useModalHandlers} from '../../core/ModalUseProvider';
import ExportToExcelModal from '../../../../user-management/users-list/export-modal/ExportToExcelModal';

const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView();
  const openAddUserModal = () => {
    setItemIdForUpdate(null);
  };

  const translate = useTranslate();
  const {updateState} = useQueryRequest();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const option = translate('INPUT-OPTION-1');
  const [startOption, setStartOption] = useState('1');
  let {idParam} = useParams();

  const filterData = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === '1') {
      updateState({filter: undefined, ...initialQueryState});
      return;
    }
    if (selectedOption !== 'customrange') {
      updateState({
        filter: {type: selectedOption},
        ...initialQueryState,
      });

      setStartOption(selectedOption);
    } else {
      setShowCreateAppModal(true);
      setStartOption(option);
    }
  };

  const {open, setOpen} = useModalHandlers();
  return (
    <>
      <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
        <div className='me-3'>
          <select
            className='form-select form-select-solid custom-filter-button'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            onChange={filterData}
            value={startOption}
          >
            <option value='1'>{option}</option>
            <option value='today'>{translate('INPUT-OPTION-2')}</option>
            <option value='lastweek'>{translate('INPUT-OPTION-3')}</option>
            <option value='currentmonth'>{translate('INPUT-OPTION-4')}</option>
            <option value='lastmonth'>{translate('INPUT-OPTION-5')}</option>
            <option value='customrange'>{translate('INPUT-OPTION-6')}</option>
          </select>
        </div>
        <div className='custom-button-table'>
          <button type='button' className='btn btn-light-success' onClick={openAddUserModal}>
            {/* <KTIcon iconName='plus' className='fs-2' /> */}
            {translate('USERS.TABLE-LOGS-ADD')}
          </button>
          <span> </span>
          <button type='button' className='btn btn-light-success' onClick={() => setOpen(true)}>
            {translate('BUTTON-ADMIN-EXPORT')}
          </button>
        </div>

        {/* end::Add user */}
        <FindAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
      </div>
       {open && <ExportToExcelModal />}
    </>
  );
};

export {UsersListToolbar};
