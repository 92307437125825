import { Navigate, Outlet } from "react-router-dom";
import { optionsRoles } from '../constants/routes.roles'

export const validateRouteAccess = ({
  userRole,
  module,
  redirectTo = "/user-logs",
  customValidation = false
}) => {
  const allowedRoles = optionsRoles[module] || [];
  return allowedRoles.length === 0 || allowedRoles.includes(userRole);
};

export function convertAMPMto24Hours(ampmTime) {
  const parts = ampmTime.split(" ");
  const time = parts[0];
  const period = parts[1];
  const [hours, minutes] = time.split(":");

  
  let hours24 = parseInt(hours, 10);
  if (period === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (period === "AM" && hours24 === 12) {
    hours24 = 0;
  }
  
  const hours24String = hours24.toString().padStart(2, "0");
  
  return `${hours24String}:${minutes}`;
}


