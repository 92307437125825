import React from 'react';
import { CustomInputsProps } from './types';
import clsx from 'clsx';
import './CustomInput.css';

const CustomInput: React.FC<CustomInputsProps> = ({
  name,
  errors,
  fieldProps,
  touched,
  autoComplete,
  placeholder,
  icon,
  label,
  type = 'text',
  onTextChange,
  numIconSpansPass = 6,
  numIconSpansInput = 6,
  classNameLabel = 'form-label fs-6 fw-bolder text-dark',
  className= 'form-control d-flex align-items-center ',
  style={},
  passwordIcon = true,
  classContainer = '',
  classSize= '',
  onChange,
  onBlur,
  value,
  disabled= false
}) => {
  const [inputType, setInputType] = React.useState(type);

  const toggleInputType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  }  
  const onHandleChangeText = () => {
    if(onTextChange){
       onTextChange(false);
    }
  };
  const spansPass: any[] = [];
  for (let i = 1; i <= numIconSpansPass; i++) {
    spansPass.push(<span key={i} className={`path${i}`}></span>);
  }
  const spansInput: any[] = [];
  for (let i = 1; i <= numIconSpansInput; i++) {
    spansInput.push(<span key={i} className={`path${i}`}></span>);
  }

  return (
    <div className={classContainer}>
      {label && (<label className={` ${classNameLabel}`}>{label}</label>)}
      <div className={`${className}`} style={style}>
        {icon && <i className={`${icon} fs-3 me-2`} style={{ color: "#555555" }}>{spansInput}</i>}
        {value ? <input
          placeholder={placeholder}
          {...fieldProps}
          className={clsx(
            `bg-transparent w-100 form-control-plaintext p-0 ${classSize}`,
            {'is-invalid': touched && errors},
            {
              'is-valid': touched && !errors,
            }
          )} 
          type={inputType}
          name={name}
          autoComplete={autoComplete}
          onClick={onHandleChangeText}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled = {disabled}
        />: 
        <input
          placeholder={placeholder}
          {...fieldProps}
          className={clsx(
            `bg-transparent w-100 form-control-plaintext p-0 ${classSize}`,
            {'is-invalid': touched && errors},
            {
              'is-valid': touched && !errors,
            }
          )} 
          type={inputType}
          name={name}
          autoComplete={autoComplete}
          onClick={onHandleChangeText}
          disabled = {disabled}
        />}
        {type === 'password' && passwordIcon && (
          <i
            className={`ki-duotone  ${inputType === 'password' ? 'ki-eye-slash' : 'ki-eye'} fs-3 me-2 cursor-pointer`}
            onClick={toggleInputType}
            style={{ color: "#555555" }}
          >
            {spansPass}
          </i>
        )}
      </div>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <span role='alert' className='text-danger'>{errors}</span>
        </div>
      )}
    </div>
  )
}

export default CustomInput
