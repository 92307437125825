import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {UserModel} from '../../../modules/auth';
import {formFeedbackSchema, initialValues} from '../constants';
import {addFeedbackForm, checkFormIsFilled} from '../core/_requests';

export const useFormFeedback = () => {
  const {userId: userParam, managerId: managerParam, evalDate} = useParams();
  const userId = userParam ? parseInt(userParam) : 0;
  const managerId = managerParam ? parseInt(managerParam) : 0;
  const [loading, setLoading] = useState(true);
  const [alreadyFilled, setAlreadyFilled] = useState(false);
  const [formJustSent, setFormJustSent] = useState(false);
  const [user, setUser] = useState({} as UserModel);

  const {handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid} = useFormik({
    initialValues,
    validationSchema: formFeedbackSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        console.log('values', values, userId, managerId, evalDate);
        if (formJustSent) {
          toast.error('You have already filled the form');
          return;
        }

        if (!(userId && managerId && evalDate)) {
          return toast.error('Invalid URL');
        }

        const {data} = await addFeedbackForm(userId, managerId, values.question1, values.question2, evalDate);
        setFormJustSent(true);
      } catch (error: any) {
        console.log(error);
        const errorMessage = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message.join(', ')
          : error?.response?.data?.message;
        toast.error(errorMessage);
        setStatus('AUTH.AXIOS:ERROR');
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    getUseData();
  }, []);

  const getUseData = async () => {
    setLoading(true);
    try {
      console.log(userId, managerId, evalDate);
      if (!(userId && managerId && evalDate)) {
        return toast.error('Invalid URL');
      }
      const {data} = await checkFormIsFilled(userId, managerId, evalDate);

      setAlreadyFilled(data.exists);
      setUser(data.user);
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  return {
    loading,
    formJustSent,
    alreadyFilled,
    user,
    touched,
    errors,
    isSubmitting,
    isValid,
    evalDate,
    handleSubmit,
    getFieldProps,
  };
};
