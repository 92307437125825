import { FC, useState, createContext, useContext } from "react";
import { initialModal, WithChildren } from "../../../../../../_metronic/helpers";

const ModalUseContext = createContext(initialModal);

const ModalUseProvider: FC<WithChildren> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);

  return (
    <ModalUseContext.Provider
      value={{
        open,
        setOpen,
        id,
        setId,
      }}
    >
      {children}
    </ModalUseContext.Provider>
  );
};

const useModalHandlers = () => useContext(ModalUseContext);

export { ModalUseProvider, useModalHandlers };
