import {useState} from 'react';
import {KTIcon} from '../../../../../../../_metronic/helpers';
import {useTranslate} from '../../../../../../function/translate';
import {useListView} from '../../core/ListViewProvider';
import {FindAppModal} from '../../findModal/findAppModal';

const ClientManagersListToolbar = () => {
  const {setItemIdForUpdate} = useListView();
  const translate = useTranslate();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const openAddUserModal = () => setItemIdForUpdate(null);

  return (
    <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
      <div className='custom-button-add'>
        <button type='button' className='btn btn-success ' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          {translate('CLIENT_MANAGER.ADD-USER')}
        </button>
      </div>
      <FindAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
    </div>
  );
};

export {ClientManagersListToolbar};
