/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES, initialQueryState} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import { deleteUserLog} from '../../core/_requests'
import { toast } from 'react-toastify'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useTranslate } from '../../../../../../function/translate'
import { DeleteAlert, DeleteCompleted } from '../../../../../../hooks/customAlert'
import Swal from 'sweetalert2'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate, onSelect, selected} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const translate = useTranslate();

  const deleteItem = useMutation(() => deleteUserLog(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message ||'')
      DeleteCompleted(translate('ALERT-DELETE'),translate('ALERT-DELETE2'));
      selected.includes(id) && onSelect(id)
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      
    },
    onError: (error: any) => {
      toast.success(error?.message ||'')
    },
  })
  
  const deleteF = async()=>{
    const confirmed = await DeleteAlert({
      title: translate('ALERT-TITLE'),
      text: translate('ALERT-TEXT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('ALERT-CONFIRM')
    });
    if (confirmed) {
      deleteItem.mutateAsync();
    }
}

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm custom-button-table-action'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {translate('TABLE-ACTIONS')}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 submenu-custom'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {translate('TABLE-MENU-EDIT')}
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link submenu-custom text-danger px-3'
            data-kt-users-table-filter='delete_row'
            onClick={ 
              async () => {
                deleteF()
              }
            }
          >
            {translate('TABLE-MENU-DELETE2')}
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
