import {KTCard} from '../../../../../_metronic/helpers';
import {ClientManagersListHeader} from './components/header/ClientManagersListHeader';
import {ListViewProvider, useListView} from './core/ListViewProvider';
import {QueryRequestProvider} from './core/QueryRequestProvider';
import {QueryResponseProvider} from './core/QueryResponseProvider';
import {ClientManagersTable} from './table/ClientManagersTable';
import {ClientManagerEditModal} from './user-edit-modal/ClientManagerEditModal';

const ClientManagersList = () => {
  const {itemIdForUpdate} = useListView();

  return (
    <>
      <KTCard>
        <ClientManagersListHeader />
        <div className='separator my-2'></div>
        <ClientManagersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ClientManagerEditModal />}
    </>
  );
};

const ClientManagersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClientManagersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export {ClientManagersListWrapper};
