import {Route, Routes, Outlet, Navigate, useParams, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UsersListWrapper} from './users-list/UsersList'
import { useEffect } from 'react'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User list',
    path: '/users/:id/history',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersLogPage = () => {
  
  const navigate = useNavigate()
  let {idParam} = useParams()
  useEffect(() => {
    if (!idParam || idParam === undefined || isNaN(+idParam)) {
      navigate('/users')
    }
  }, [idParam, navigate]);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='history'
          element={
            <>
              <UsersListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default UsersLogPage
