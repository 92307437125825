import { useEffect, useState } from "react"
import { dataSchema, initialValues } from "../constants"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { initialQueryState } from "../../../../../../../_metronic/helpers"
import { useQueryRequest } from "../../core/QueryRequestProvider"
import moment from "moment"

export const useFindModal = () => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMsj, setErrorMsj] = useState(false)
  const [closeModal, setCloseModal] = useState(false)
  const {updateState} = useQueryRequest()

  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid, values } = useFormik({
    initialValues,
    validationSchema: dataSchema,
    onSubmit: async (values,  {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const date1 = moment(values.date1).format('YYYY-MM-DD');
        const date2 = moment(values.date2).format('YYYY-MM-DD');
        updateState({
          filter: {date1: date1, date2: date2},
          ...initialQueryState,
        })
        setCloseModal(true);
        setHasErrors(false)
        setLoading(false)
        // navigate(`/auth/change-password`, { state: {} })
        
      } catch (error: any) {
        console.log(error)
        setCloseModal(true);
        setErrorMsj(error);
        toast.error(error)
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('AUTH.AXIOS:ERROR')
      }
    },
  })

  return {
    loading,
    hasErrors,
    handleSubmit,
    errorMsj,
    getFieldProps,
    errors,
    touched,
    isSubmitting,
    setHasErrors,
    isValid,
    values,
    closeModal,
    setCloseModal
  }
}
