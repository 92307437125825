import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password`

export function changePassword(newPassword: string, confirmPassword: string) {
  return axios.post(CHANGE_PASSWORD_URL, {
    isTemporaryPass: true,
    newPassword,
    confirmPassword,
  })
}
