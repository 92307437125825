import { useFormik } from "formik"
import { useState } from "react"
import { toast } from "react-toastify"
import { changePasswordSchema, initialValues } from "../constants"
import { changePassword } from "../core/_requests"
import { useNavigate } from "react-router-dom"
import { AuthModel, useAuth } from "../../../modules/auth"
import showAlert from "../../../hooks/customAlert"
import { useTranslate } from "../../../function/translate"

export const useChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const navigate = useNavigate()
  const { auth, saveAuth, logout } = useAuth()
  const translate = useTranslate();

  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid } = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const { data }= await changePassword(values.newPassword, values.confirmNewPassword)
        toast.success(data.message)
        setHasErrors(false)
        setLoading(false)
        const newAuth = { ...auth } as AuthModel
        if (newAuth.isTemporaryPass) {
          newAuth.isTemporaryPass = false
          saveAuth(newAuth)
        }
        showAlert({
          title: translate('ALERT-CHANGE-PASS'),
          buttonText: translate('ALERT-CHANGE-PASS-BUTTON'),
        }).then((result) => {
          logout()
        });;
        
        
        navigate('/dashboard')
      } catch (error: any) {
        toast.error(error.response?.data?.message)
        setErrorMessage(error.response?.data?.message)
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('Something went wrong. Please try again.')
      }
    },
  })

  return {
    loading,
    hasErrors,
    setHasErrors,
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    isSubmitting,
    isValid,
    errorMessage
  }
}