type Department = {
  id: number;
  name: string;
  parent: number;
};

export const setSelectOptions = (data: any) => {
  //const selectOptionsType: { value: string; option: string }[] = [];

  if (data.length === 0) {
    return [];
  }
  const selectOptions = data.map((item) => ({
    value: item.id.toString(),
    option: item.name,
  }));

  return selectOptions;
};

export const setDepartmentSelectOptions = (data: any) => {
  if (data.length === 0) return [];

  const orderedOptions = orderSelectOptions(data);

  const selectOptions = orderedOptions.map((item) => ({
    value: item.id.toString(),
    option: item.name,
  }));

  return selectOptions;
};

const orderSelectOptions = (data: Department[], parentId: number = 0, level: number = 0): any[] => {
  let result: Array<any> = [];
  let spaces = '';

  if (level >= 1) spaces = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'.repeat(level - 1) + '\u2514\u2500\u00A0';

  data
    .filter((item) => item.parent === parentId)
    .forEach((item) => {
      result.push({
        name: spaces + item.name,
        id: item.id,
      });
      result = result.concat(orderSelectOptions(data, item.id, level + 1));
    });
  return result;
};

export const cleanPhoneNumber = (phone?: string) => {
  if (!phone) return phone;
  // Remove all non-digits to left only 10 numbers
  return phone.replace(/\D/g, '').slice(-10);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};
