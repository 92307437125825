import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Nav, TabContent, TabPane } from "reactstrap";
import { KTCard } from "../../../../../_metronic/helpers";
import TabLink from "../../../../components/TabLink";
import { ClientFeedbackListHeader } from "./components/header/ClientFeedbackListHeader";
import { UsersListHeader } from "./components/header/UsersListHeader";
import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { QueryResponseProvider as QRPClientFeedback } from "./core/QueryResponseProviderClientFeedback";
import { getUserById } from "./core/_requests";
import { ClientFeedbackTable } from "./table/ClientFeedbackTable";
import { UsersDetails } from "./table/UsersDetails";
import { UsersTable } from "./table/UsersTable";
import { UserEditModal } from "./user-edit-modal/UserEditModal";

const UsersList = () => {
  const { itemIdForUpdate } = useListView();
  const navigate = useNavigate();
  let { idParam } = useParams();

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [idParam, navigate]);

  const fetchData = async () => {
    if (!idParam || idParam === undefined || isNaN(+idParam)) {
      navigate("/users");
    } else if (!userData) {
      try {
        const { data } = await getUserById(+idParam);

        if (data[0]) {
          setUserData(data[0]);
        } else {
          navigate("/users");
        }
      } catch (error: any) {
        toast.error(error?.message || "");
      }
    }
  };

  const toggleCustom = (tab: string) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <>
      <UsersDetails userData={userData} />
      <div className=' py-5 py-lg-2'>
        <KTCard>
          <Nav tabs className='nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3'>
            <TabLink index='1' label='Time Logs' customActiveTab={customActiveTab} toggleCustom={toggleCustom} />
            <TabLink index='2' label='Client Feedback' customActiveTab={customActiveTab} toggleCustom={toggleCustom} />
          </Nav>

          <TabContent activeTab={customActiveTab} className=''>
            <TabPane tabId='1'>
              <UsersListHeader />
              <div className='separator my-2'></div>
              <UsersTable userData={userData} />
            </TabPane>
            <TabPane tabId='2'>
              <QueryRequestProvider>
                <QRPClientFeedback>
                  <ListViewProvider>
                    <ClientFeedbackListHeader />
                    <div className='separator my-2'></div>
                    <ClientFeedbackTable />
                  </ListViewProvider>
                </QRPClientFeedback>
              </QueryRequestProvider>
            </TabPane>
          </TabContent>
        </KTCard>
      </div>

      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  );
};

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { UsersListWrapper };
