import axios from 'axios'
import {FilledFormResponse} from './_models'

const API_URL = `${process.env.REACT_APP_API_URL}/client-feedback`

export const GET_IF_FORM_FILLED_URL = `${API_URL}/exists-for-user-by-date`

export async function checkFormIsFilled(userId: number, managerId: number, evalDate: string) {
  const {data} = await axios.get<FilledFormResponse>(`${GET_IF_FORM_FILLED_URL}/${userId}/${managerId}/${evalDate}`)
  return data
}

export function addFeedbackForm(
  user: number,
  client_manager: number,
  question1: number,
  question2: string,
  evaluation_date: string
) {
  return axios.post(API_URL, {
    user,
    client_manager,
    question1,
    question2,
    evaluation_date,
  })
}
