// @ts-nocheck
import { Column } from "react-table";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";
import { User } from "../../core/_models";
import { UserInfoCell } from "./UserInfoCell";
import { UserDepartment } from "./UserDepartment";
import { UserStatusCell } from "./UserStatusCell";

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='DASHBOARD-ADMIN-NAME' className='w-300px custom-header-title' />
    ),
    id: "name",
    Cell: ({ ...props }) => {
      return (
        <div>
          {props.data[props.row.index]?.name ? (
            <UserInfoCell user={props.data[props.row.index]} />
          ) : (
            <div style={{ paddingLeft: "20px" }}>___</div>
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='DASHBOARD-ADMIN-DEPARTMENT' className='w-175px custom-header-title' />
    ),
    id: "departments",
    Cell: ({ ...props }) => {
      return (
        <div>
          {props.data[props.row.index]?.departments ? (
            <UserDepartment departments={props.data[props.row.index]?.departments} />
          ) : (
            <div style={{ paddingLeft: "20px" }}>___</div>
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='STATUS' className='w-175px custom-header-title' />,
    id: "status",
    Cell: ({ ...props }) => {
      return (
        <div>
          {props.data[props.row.index]?.status ? (
            <UserStatusCell status={props.data[props.row.index]?.status} />
          ) : (
            <div style={{ paddingLeft: "20px" }}>___</div>
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='DASHBOARD-ADMIN-ACTIONS'
        className='text-end min-w-200px custom-header-title'
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
];

export { usersColumns };
