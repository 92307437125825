import { useListView } from "../../core/ListViewProvider";
import AdminUserFilters from "./UserDepartmentFilter";
import { UsersListToolbar } from "./UserListToolbar";
import { UsersListSearchComponent } from "./UsersListSearchComponent";

const UsersListHeader = () => {
  return (
    <div className='card-header border-0'>
      <UsersListSearchComponent />
      <AdminUserFilters />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export { UsersListHeader };
