import { FC } from "react";

type Props = {
  status: any;
};

const UserStatusCell: FC<Props> = ({ status }) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column custom-table-data'>
        {status === 1 ? "Active" : status === 2 ? "Archived" : "Suspended"}
      </div>
    </div>
  );
};

export { UserStatusCell };
