// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";
import { User } from "../../core/_models";
import { useTranslate } from "../../../function/translate";
import { useModalHandlers } from "../../../modules/apps/user-log-history/users-list/core/ModalUseProvider";
import moment from "moment";

type Props = {
  row: Row<User>;
  hasReviews?: boolean;
};

const CustomRow: FC<Props> = ({ row, hasReviews = false }) => {
  const translate = useTranslate();
  const { setOpen, setId } = useModalHandlers();

  const { clockIn, clockOut, needsReview } = row.original;

  const clockInDate = moment(clockIn);
  const clockOutDate = moment(clockOut);
  const durationInHours = moment.duration(clockOutDate.diff(clockInDate)).asHours();

  const backgroundColorClass = clsx({
    "bg-warning": needsReview && durationInHours <= 15,
    "bg-danger text-white": durationInHours > 15 || clockOut === null,
  });

  return (
    <tr {...row.getRowProps()} className={backgroundColorClass}>
      {row.cells.map((cell) => {
        // Determine the background color class
        return (
          <td
            {...cell.getCellProps()}
            className={clsx("time-log-row", {
              "text-end min-w-100px": cell.column.id === "actions",
            })}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
      {hasReviews && (
        <td
          className='cursor-pointer'
          onClick={() => {
            if (row.original.needsReview) {
              return;
            }
            setOpen(true);
            setId(row.original.id);
          }}
        >
          {!row.original.needsReview ? translate("REQUEST-REVIEW") : "Reviewing"}
        </td>
      )}
    </tr>
  );
};

export { CustomRow };
