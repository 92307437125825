/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import { useLoginForm } from './hooks/useLoginForm'
import CustomInput from '../../../../components/CustomInput'
import { focusItoImg } from '../../../../constants/assets';
import { useIntl } from 'react-intl';

export function Login() {
  const { errors, getFieldProps, handleSubmit, loading, touched, isSubmitting, isValid } = useLoginForm();
  const intl = useIntl()
  return (
    <form
      className='form w-100'
      onSubmit={handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <img src={focusItoImg} alt='' className='w-75 mx-auto' />
        <div className='custom-login-title'>
          <h1 className='text-dark fw-bolder mt-8 mb-3 '>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'}) } </h1>
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <CustomInput
          name='email'
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          type='email'
          touched={touched.email}
          errors={errors?.email ? intl.formatMessage({ id: errors.email }) : ''}
          fieldProps={getFieldProps('email')}
          autoComplete='off'
          icon="ki-duotone ki-user"
          className= 'form-control d-flex align-items-center input-style'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <CustomInput
          name='password'
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
          type='password'
          touched={touched.password}
          errors={errors?.password ? intl.formatMessage({ id: errors.password }) : ''}
          fieldProps={getFieldProps('password')}
          autoComplete='off'
          icon="ki-duotone ki-lock-2"
          className= 'form-control d-flex align-items-center input-style'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
      <div/>

        {/* begin::Link */}
        <div className='custom-link-container'>
        <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}
        </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      
      <div className='container-button d-grid mb-10 custom-button'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-success'
          disabled={isSubmitting || !isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({ id: 'AUTH.LOGIN.LOADING' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>        
      </div>
      {/* end::Action */}
    </form>
  )
}
