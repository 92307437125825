import { useEffect, useState } from "react"
import { verificationCodeSchema, initialValues } from "../constants"
import { useFormik } from "formik"
import { validatePassword } from "../../../core/_requests"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

export const useCodeVerification = (initialEmail) => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [email, setEmail] = useState<string>(initialEmail)
  const navigate = useNavigate()
  const [errorMsj, setErrorMsj] = useState(false)

  useEffect(() => {
    if (initialEmail) {
      const email_qp = initialEmail as string
      setEmail(email_qp)
    } else {
      navigate('/auth')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid, setFieldValue } = useFormik({
    initialValues,
    validationSchema: verificationCodeSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const { data } = await validatePassword(email, values.code.toString())
        toast.success(data.message)
        
        const dataToSend = {
          email: email,
          token: data?.data?.accessToken,
        };
        navigate(`/auth/change-password`, { state: dataToSend })
        setHasErrors(false)
        setLoading(false)
        localStorage.setItem('countdown', "0");
        localStorage.setItem('isResendClicked', "false");
      } catch (error: any) {
        setErrorMsj(error?.response?.data?.message);
        toast.error(error?.response?.data?.message)
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('AUTH.AXIOS:ERROR')
      }
    },
  })

  return {
    loading,
    hasErrors,
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    isSubmitting,
    isValid,
    setFieldValue,
    setHasErrors,
    errorMsj
  }
}
