import React from "react";
import "./styles.scss";
import { CodeInputProps } from "./types";
import OTPInput from "./BaseInput";

const CodeInput: React.FC<CodeInputProps> = ({
  name,
  onChange,
  label,
  touched,
  errors,
  length = 5,
  inputMode = 'numeric',
  type = 'number',
  classLabel = "form-label fw-bold",
  value
}) => {
  return (
    <div>
      {label && <label className={classLabel}>{label}</label>}
      <div>
        <OTPInput
          skipDefaultStyles={true}
          inputType={type}
          onChange={onChange}
          numInputs={length}
          value={value}
          renderInput={(props) => (
            <div className='custom-code-input'>
              <input {...props} name={name} />
            </div>
          )}
        />
      </div>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <span role='alert' className='text-danger'>{errors}</span>
        </div>
      )}
    </div>
  )
}

export default CodeInput
