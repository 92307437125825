import {FC, useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {Link, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import { isString } from 'formik'
import React from 'react'

const Header: FC = () => {
  const {config} = useLayout()

  const {pathname} = useLocation()
  const intl = useIntl()
  const currentSection = pathname.slice(1).split('/')[0]
  const currentSection2 = pathname.slice(1).split('/')[1]
  const currentSection3 = pathname.slice(1).split('/')[2]

  let path2;
  let seccion2; 
  const part2 = parseInt(currentSection2, 10);
  if(isNaN(part2)){
    path2= currentSection;
    seccion2= currentSection2;
  }

  let path3;
  let seccion3; 
  const part3 = parseInt(currentSection3, 10);
  if(isNaN(part3)){
    path3= currentSection+"/"+currentSection2+"/"+currentSection3;
    seccion3= currentSection3;
  }


  useEffect(() => {
    updateDOM(config)
  }, [config, currentSection])

  return (
    <div
      className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-start
        justify-content-center
        fw-semibold
        px-2 px-lg-0
        d-flex
        flex-column
    '
      id='kt_app_header_menu'
      data-kt-menu='true'
    >
      {/** Breadcrumbs */}
      {!currentSection ? (
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
          <div> {intl.formatMessage({id: `MENU.HOME`})}</div>
        </h1>
      ) : (
        <>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center title-bread'>
            {intl.formatMessage({id: `MENU.${currentSection.toUpperCase()}`})}
          </h1>
          <div className='d-flex gap-1 breadcrumb-item text-muted custom-sub-title-bread'>
            <Link className='breadcrumb-item text-muted' to={currentSection}>
              {intl.formatMessage({id: `MENU.${currentSection.toUpperCase()}`})}
            </Link>
            { seccion2 ? 
            <Link className='text-muted' to={path2}>
              - {seccion2}
            </Link>
            : null

          }
          { seccion3 ? 
            
            <Link className='text-muted' to={path3}>
              - {seccion3}
            </Link>
            : null

          }
            {/*<div>-</div>
            <div>{intl.formatMessage({id: `MENU.${currentSection.toUpperCase()}`})}</div>*/}
          </div>
         
          
        </>
      )}
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute('data-kt-app-header-fixed', 'true')

    document.body.setAttribute('data-kt-app-header-minimize', 'on')
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true')
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute('data-kt-app-header-stacked', 'true')
  }

  const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled
  let appHeaderDefaultStickyAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultStickyEnabled) {
    appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
      [attrName: string]: string
    }
  }

  const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled
  let appHeaderDefaultMinimizeAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultMinimizeEnabled) {
    appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
      [attrName: string]: string
    }
  }

  setTimeout(() => {
    const headerElement = document.getElementById('kt_app_header')
    // header
    if (headerElement) {
      const headerAttributes = headerElement
        .getAttributeNames()
        .filter((t) => t.indexOf('data-') > -1)
      headerAttributes.forEach((attr) => headerElement.removeAttribute(attr))

      if (appHeaderDefaultStickyEnabled) {
        for (const key in appHeaderDefaultStickyAttributes) {
          if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultStickyAttributes[key])
          }
        }
      }

      if (appHeaderDefaultMinimizeEnabled) {
        for (const key in appHeaderDefaultMinimizeAttributes) {
          if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultMinimizeAttributes[key])
          }
        }
      }
    }
  }, 0)
}

export {Header}
