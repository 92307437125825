import {Link, useLocation, useParams} from 'react-router-dom'
import { useCodeVerification } from './hooks/useCodeVerification'
import CodeInput from '../../../../components/CodeInput'
import CustomAlert from '../../../../components/CustomAlert'
import { useNavigate } from "react-router-dom"
import { useForgotPasswordRequest } from "./hooks/useForgotPasswordRequest";
import { useEffect, useState } from 'react'
import "../../../../components/CodeInput/styles.scss";
import { useIntl } from 'react-intl'

export function CodeVerification() {
  
  const location = useLocation();
  const { state } : any= location;
  const [email, setEmail] = useState<string>(state?.email)
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isResendClicked, setIsResendClicked] = useState(false);
  useEffect(() => {
    setEmail(state?.email)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {
    errors,
    handleSubmit,
    hasErrors,
    isSubmitting,
    isValid,
    loading,
    touched,
    setHasErrors,
    setFieldValue,
    errorMsj
  } = useCodeVerification(email);
  const closeError = () => {
    setHasErrors(undefined);
  };
  const intl = useIntl()
  const navigate = useNavigate()
  const { sendForgotPasswordRequest } = useForgotPasswordRequest();
  useEffect(() => {
    const savedCountdown = parseInt(localStorage.getItem('countdown') || '0', 10);
    const savedResendClicked = localStorage.getItem('isResendClicked') === 'true';

    if (savedResendClicked && savedCountdown > 0) {
      setCountdown(savedCountdown);
      setIsResendClicked(true);
      setResendDisabled(true);

      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            // Enable the "Resend" link when the countdown reaches zero
            setResendDisabled(false);
            setIsResendClicked(false);
            clearInterval(timer);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('countdown', countdown.toString());
    localStorage.setItem('isResendClicked', isResendClicked.toString());
  }, [countdown, isResendClicked]);
  
  const handleForgotPassword = async () => {
    if(isResendClicked && countdown > 0) return;
    const success = await sendForgotPasswordRequest(email);
    setResendDisabled(true);
    setCountdown(120);
    setIsResendClicked(true);

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          setResendDisabled(false);
          setIsResendClicked(false);
          clearInterval(timer);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    if (!success) {
      setResendDisabled(false);
      setCountdown(0);
      setIsResendClicked(false);
      localStorage.setItem('countdown', "0");
      localStorage.setItem('isResendClicked', "false");
      
      const dataToSend = {
        email,
      };
      navigate(`/auth/forgot-password/email-verification`, { state: dataToSend })
    }
  };
  const handleRedirect = () => {
    navigate(`/auth/forgot-password`)
  };


  return (
    <><form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-start mb-10'>
        {/* begin::Title */}
        <div className="custom-verification-title">
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'AUTH.VERIFICATION.CODE.TITLE'})}</h1>
        </div>
        {/* end::Title */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <CustomAlert errorMessage={errorMsj} onClose={closeError} />
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-10' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CodeInput
          name='code'
          errors={errors?.code ? intl.formatMessage({ id: errors.code }) : ''}
          touched={touched.code}
          classLabel='custom-verification-text'
          label={intl.formatMessage({id: 'AUTH.VERIFICATION.CODE.TITLE.SEC'})}
          onChange={(value) => {
            setFieldValue('code', value)
          } } />
      </div>
      {/* end::Form group */}
      <div className='container-button d-grid mb-5 custom-button'>
      <button type='submit' id='kt_password_reset_submit' className='btn btn-success' style={{color: 'white'}}>
          <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}</span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'GENERAL.PROGRESS'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      
      <div className='container-button d-grid mb-10 container-button-light'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            onClick={handleRedirect}
            disabled={isSubmitting || !isValid}
            style={{color: '#989898'}}
          >
            {intl.formatMessage({id: 'GENERAL.CANCEL.BUTTON'})}
          </button>
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
      <div  className='get-code-label'>
      {intl.formatMessage({id: 'AUTH.VERIFICATION.CODE.LABEL'})}
      </div>
      
      {!isResendClicked && countdown === 0 && (
        <Link to="#"
        onClick={(e) => {
          e.preventDefault();
          if (!isResendDisabled) {
            handleForgotPassword();
          }
        }}
        style={{ marginLeft: '5px', color: '#3E97FF',
              fontSize: '14px',
              fontFamily: 'Hind',
              fontWeight: 500,
              lineHeight: '22.43px',
              wordWrap: 'break-word',}}>
          {intl.formatMessage({id: 'AUTH.VERIFICATION.CODE.RESEND'})}
        </Link>
      )}
      {isResendClicked && countdown > 0 && (
        <span className='get-code-label' style={{ marginLeft: '5px' }}>{intl.formatMessage({id: 'AUTH.VERIFICATION.CODE.RESEND'})} {countdown} seg</span>
      )}
    </div>
      {/* end::Form group */}
    </form>
    
    </>
  )
}
