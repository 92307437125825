import axios from 'axios';
import {ID} from '../../../../../../_metronic/helpers';
import {ClientManager} from './_models';

const API_URL = `${process.env.REACT_APP_API_URL}`;
const ENDPOINT_URL = `${API_URL}/client-managers`;

const getClientManagers = (query: string) => {
  return '';
};

const getClientManagerById = async (id: ID) => {
  const {data} = await axios.get(`${ENDPOINT_URL}/${id}`);
  return data.data;
};

const createClientManager = async (user: ClientManager) => {
  //Clone user values to avoid changing the original object
  const dataToSend = {...user, department: user.department_id};
  delete dataToSend.department_id;

  const data: any = await axios.post(ENDPOINT_URL, dataToSend);

  return data;
};

export const getUseData = async (query: string) => {
  const data: any = await axios.get(`${ENDPOINT_URL}?${query}`);
  const dataResponse = data.data;
  return dataResponse;
};

export const getDepartments = async () => {
  const data: any = await axios.get(`${API_URL}/department`);
  const dataResponse: any = data?.data;

  return dataResponse;
};

export const getAllDepartments = async () => {
  const data: any = await axios.get(`${API_URL}/department/all`);
  const dataResponse: any = data?.data;

  return dataResponse;
};

export const updateClientManager = async (user: ClientManager, id: ID) => {
  //Clone user values to avoid changing the original object
  const dataToSend = {...user, department: user.department_id};
  delete dataToSend.department_id;
  delete dataToSend.id;

  const data: any = await axios.put(`${ENDPOINT_URL}/${id}`, dataToSend);

  return data;
};

export const sendEmailForm = async (id: any) => {
  const {data} = await axios.post(`${API_URL}/client-feedback/send-email/${id}`);

  return data;
};

export const deleteClientManager = async (id: any) => {
  const data: any = await axios.delete(`${ENDPOINT_URL}/${id}`);

  return data;
};

export {createClientManager, getClientManagerById, getClientManagers};
