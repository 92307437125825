import {toast} from 'react-toastify';
import {getAllDepartments, getDepartments} from '../../core/_requests';

export const getDepartmentsFetch = async (includeTeams = false) => {
  try {
    const {data} = await getDepartments();
    if (!includeTeams) {
      return {data: data?.data?.filter((department: any) => department.type !== 'Team')};
    } else return data;
  } catch (error: any) {
    // An error occurred during the operation
    toast.error(error?.message || '');
    return null; // Return null or handle the error appropriately
  }
};

export const getAllDepartmentsFetch = async () => {
  try {
    const {data} = await getAllDepartments();
    return data;
  } catch (error: any) {
    // An error occurred during the operation
    toast.error(error?.message || '');
    return null; // Return null or handle the error appropriately
  }
};
