// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";
import { User } from "../../core/_models";
import moment from "moment";

type Props = {
  row: Row<User>;
};

const CustomRow: FC<Props> = ({ row }) => {
  console.log(row);
  return (
    <tr
      {...row.getRowProps()}
      className={clsx({
        // Calculate totalHours from clockIn and clockOut
        "bg-danger text-white":
          moment.duration(moment(row?.original?.clockOut).diff(moment(row?.original?.clockIn))).asHours() > 15 ||
          row?.original?.clockOut === null,
        "bg-warning":
          row?.original?.needsReview &&
          moment.duration(moment(row?.original?.clockOut).diff(moment(row?.original?.clockIn))).asHours() <= 15,
      })}
    >
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className={clsx({ "text-end min-w-100px": cell.column.id === "actions" })}>
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export { CustomRow };
