import {KTIcon} from '../../../../../../_metronic/helpers'
import { useTranslate } from '../../../../../function/translate';
import {useListView} from '../core/ListViewProvider'

const UserEditModalHeader = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const translate = useTranslate();

  return (
    <div className='modal-header' style={{ borderBottom: 0, paddingBottom: '10px' }}>
      {/* begin::Modal title */}
      {itemIdForUpdate ? (
        <div className='custom-title-log'>{translate('FORM-LOG-TITLE')}</div>
      ) : <div className='custom-title-log'>{translate('TABLE-MENU-ADD')}</div>}

      
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='abstract-11' iconType='solid' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {UserEditModalHeader}
