import { toast } from "react-toastify";
import { requestCode } from "../../../core/_requests";
import { useNavigate } from "react-router-dom";

export const useForgotPasswordRequest = () => {
  const navigate = useNavigate()
  const sendForgotPasswordRequest = async (email) => {

    try {
      const { data } = await requestCode(email);
      const dataToSend = {
        email,
      };
      navigate(`/auth/forgot-password/email-verification`, { state: dataToSend })
      toast.success(data.message);
      return true;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      return false;
    }
  };

  return {
    sendForgotPasswordRequest,
  };
};
