import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useResetPassword } from './hooks/useForgotPassword'
import CustomInput from '../../../../components/CustomInput'
import CustomAlert from '../../../../components/CustomAlert';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate()
  let { state } : any= location;
  const [email, ] = useState<string>(state?.email)
  const [token, ] = useState<string>(state?.token)
  const intl = useIntl()
  useEffect(() => {
    if (!email || !token) {
      navigate("/auth");
    }
    return () => {
      state = null;
    };
  }, [email, token, navigate]);
  const {
    errors,
    getFieldProps,
    handleSubmit,
    hasErrors,
    errorMsj,
    setHasErrors,
    loading,
    touched
  } = useResetPassword(email, token);
  const closeError = () => {
    setHasErrors(undefined);
  };
  const handleForgotPassword = async () => {
    navigate(`/auth/change-password`, { state: null });
    navigate(`/auth/login`, { state: null })

  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-start mb-10'>
        <div className='change-pass-h1'>
        <h1 >{intl.formatMessage({id: 'AUTH.CHANGE.TITLE.FIRST'})}</h1>
        </div>
        
        <div className='base-label-change change-pass-label'> 
            {intl.formatMessage({id: 'AUTH.CHANGE.TITLE.SECOND'})}    
          
          <Link onClick={(e) => {
            e.preventDefault();
              handleForgotPassword();
          }} to="#" style={{ marginLeft: '5px', color: '#5494FA',
            fontSize: '14px',
            fontFamily: 'Hind',
            fontWeight: 600,
            lineHeight: '22.43px',
            wordWrap: 'break-word', }}>
          {intl.formatMessage({id: 'AUTH.CHANGE.TITLE.SECOND.LINK'})}
          </Link>
        </div>
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <CustomAlert errorMessage={errorMsj} onClose={closeError} />
      )}
      
      <div className="mb-7 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <CustomInput
            autoComplete='off'
            errors={errors?.password ? intl.formatMessage({ id: errors.password }) : ''}
            touched={touched.password}
            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.NEW.PASSWORD' })}
            type='password'
            fieldProps={getFieldProps('password')}
            name='password'
            icon="ki-duotone ki-lock-2"
            className= 'form-control d-flex align-items-center input-style'
            />
          </div>
          <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
        </div>
        <div className="base-style  change-validation-label" data-kt-translate="new-password-hint">
          {intl.formatMessage({id: 'AUTH.CHANGE:ERROR'})}
        </div>
      </div>
      <div className='fv-row mb-8'>
        <CustomInput
          autoComplete='off'
          errors={errors?.repeatPassword ? intl.formatMessage({ id: errors.repeatPassword }) : ''}
          touched={touched.repeatPassword}
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
          type='password'
          fieldProps={getFieldProps('repeatPassword')}
          name='repeatPassword'
          icon="ki-duotone ki-lock-2"
          className= 'form-control d-flex align-items-center input-style'
        />
      </div>

      <div className="mb-10 custom-container">
        <div className="custom-label">
        {intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.1'})}
        </div>
        <div className="custom-items">
          <div className="custom-item">
          <div className="custom-icon-container">
            <i className="bi bi-check2-circle"></i>
            </div>
            <div className="custom-gray-text">{intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.2'})} 
            </div>
          </div>
          <div className="custom-item">
            <div className="custom-icon-container">
            <i className="bi bi-check2-circle"></i>
            </div>
            <div className="custom-gray-text">
            {intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.3'})} 
            </div>
          </div>
          <div className="custom-item">
          <div className="custom-icon-container">
            <i className="bi bi-check2-circle"></i>
            </div>
            <div className="custom-gray-text">{intl.formatMessage({id: 'AUTH.CHANGE.LABEL.VALIDATION.4'})} 
            </div>
          </div>
        </div>
      </div>

      
  
      <div className='d-grid mb-10 custom-button' >
        <button type='submit' id='kt_password_reset_submit' className='btn btn-success me-4 btn-xl custom-button' data-kt-translate="new-password-submit">
          <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON-SET-UP'})}</span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'GENERAL.PROGRESS'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>      
      
      {/* end::Form group */}
    </form>
  )
}
