import {useFormik} from 'formik'
import {useState} from 'react'
import {useAuth} from '../../../core/Auth'
import {loginSchema, initialValues} from '../constants'
import {getUserByToken, login} from '../../../core/_requests'
import {toast} from 'react-toastify'

export const useLoginForm = () => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const {handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid} = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        console.log('values', values)
        const {data: resp} = await login(values.email, values.password)
        saveAuth(resp.data)
        const {data} = await getUserByToken()
        setCurrentUser(data.data)
      } catch (error: any) {
        console.log(error)
        toast.error(error?.response?.data?.message)
        saveAuth(undefined)
        setStatus('AUTH.AXIOS:ERROR')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return {
    loading,
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    isSubmitting,
    isValid,
  }
}
