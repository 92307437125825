import { KTIcon } from "../../../../../../_metronic/helpers";
import { useTranslate } from "../../../../../function/translate";
import { useModalHandlers } from "../../../user-log-history/users-list/core/ModalUseProvider";
import CustomTextArea from "../../../../../components/CustomTextArea";
import { useRef } from "react";
import { PostReviewRequest } from "../../../user-log-history/users-list/requests/PostReviewRequest";

function ReviewModal({ onReviewSubmitted }) {
  const { setOpen, id } = useModalHandlers();
  const review = useRef<HTMLTextAreaElement>(null);

  const translate = useTranslate();

  const handleSubmitReview = async () => {
    setOpen(false);
    await PostReviewRequest({ id: id?.toString(), description: review.current?.value });
    onReviewSubmitted();
  };

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-800px align-items-center'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div
              className='modal-header'
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: 0,
                paddingBottom: "10px",
              }}
            >
              {/* begin::Close */}
              <div
                className='modal-header'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: 0,
                  paddingBottom: "10px",
                  width: "100%",
                }}
              >
                {/* begin::Modal title */}
                <div>
                  <div className='custom-title-add'>{translate("REQUEST-REVIEW-TITLE")}</div>
                </div>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setOpen(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <KTIcon iconName='abstract-11' iconType='solid' className='fs-1' />
                  </div>
                  <div style={{ height: "45px" }}></div>
                </div>
                {/* end::Close */}
              </div>
            </div>

            {/* begin::Modal body */}
            <div className='mb-5' style={{ paddingRight: 15, paddingLeft: 15 }}>
              <div className='fv-row mb-5 '></div>
              <div className='mb-5'></div>
              <div className='mb-5 d-flex align-items-center gap-2 w-100' style={{ paddingLeft: 15 }}>
                <div className='ms-5 me-5 w-100' style={{ fontSize: 16 }}>
                  <div className='fv-row mb-3 w-100'>
                    <CustomTextArea
                      name='issue'
                      label='Describe the issue here'
                      autoComplete='off'
                      className='form-control d-flex align-items-center input-style'
                      rows={5}
                      ref={review}
                    ></CustomTextArea>
                  </div>
                  <button
                    className='btn btn-flex flex-center btn-warning overflow-hidden text-nowrap px-0 h-40px w-100'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-dismiss-='click'
                    title='Request review'
                    onClick={handleSubmitReview}
                  >
                    <span className='btn-label'>Request review</span>
                  </button>
                </div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  );
}

export default ReviewModal;
