import moment from 'moment';
import {useState} from 'react';
import {initialQueryState} from '../../../../../../../_metronic/helpers';
import {exportToExcel} from '../../../../../../function/excelHelper';
import {useTranslate} from '../../../../../../function/translate';
import {useQueryRequest} from '../../core/QueryRequestProvider';
import {useQueryResponse} from '../../core/QueryResponseProviderClientFeedback';
import {FindAppModal} from '../../findModal/findAppModal';
import {useModalHandlers} from '../../core/ModalUseProvider';
import ExportToExcelModal from '../../../../user-management/users-list/export-modal/ExportToExcelModal';

const ClientFeedbackListToolbar = () => {
  const translate = useTranslate();
  const {updateState} = useQueryRequest();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const option = translate('INPUT-OPTION-1');
  const [startOption, setStartOption] = useState('1');
  const clientFeedbacks = useQueryResponse().response?.data;

  const clientFeedbacksForExport = clientFeedbacks?.map((clientFeedback) => {
    return {
      Date: moment(clientFeedback.evaluation_date).format('MMMM YYYYY'),
      Employee: clientFeedback.user?.first_name + ' ' + clientFeedback.user?.last_name,
      ClientManager: clientFeedback.client_manager.first_name + ' ' + clientFeedback.client_manager.last_name,
      Question1: clientFeedback.question1,
      Question2: clientFeedback.question2,
    };
  });

  const filterData = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === '1') {
      updateState({filter: undefined, ...initialQueryState});
      return;
    }
    if (selectedOption !== 'customrange') {
      updateState({
        filter: {type: selectedOption},
        ...initialQueryState,
      });

      setStartOption(selectedOption);
    } else {
      setShowCreateAppModal(true);
      setStartOption(option);
    }
  };

  const {open, setOpen} = useModalHandlers();
  return (
    <>
      <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
        <div className='me-3'>
          <select
            className='form-select form-select-solid custom-filter-button'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            onChange={filterData}
            value={startOption}
          >
            <option value='1'>{option}</option>
            <option value='currentmonth'>{translate('INPUT-OPTION-4')}</option>
            <option value='lastmonth'>{translate('INPUT-OPTION-5')}</option>
            <option value='lastsixmonths'>{translate('INPUT-OPTION-7')}</option>
            <option value='customrange'>{translate('INPUT-OPTION-6')}</option>
          </select>
        </div>
        <div className='custom-button-table'>
          <button type='button' className='btn btn-light-success' onClick={() => setOpen(true)}>
            {translate('BUTTON-ADMIN-EXPORT')}
          </button>
        </div>
        <FindAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
      </div>
      {open && <ExportToExcelModal />}
    </>
  );
};

export {ClientFeedbackListToolbar};
