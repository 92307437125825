import {useFormik} from 'formik';
import {FC, useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import CustomAlert from '../../../../../components/CustomAlert';
import CustomInput from '../../../../../components/CustomInput';
import CustomSelect from '../../../../../components/CustomSelect';
import {cleanPhoneNumber, setDepartmentSelectOptions} from '../../../../../function/generalHelper';
import {useTranslate} from '../../../../../function/translate';
import {ClientManagersListLoading} from '../components/loading/ClientManagersListLoading';
import {useListView} from '../core/ListViewProvider';
import {useQueryResponse} from '../core/QueryResponseProvider';
import {ClientManager, initialClientManager} from '../core/_models';
import {createClientManager, deleteClientManager, updateClientManager} from '../core/_requests';
import dataSchema from './constants';
import {getDepartmentsFetch} from './hooks/getDepartments';
type Props = {
  isClientManagerLoading: boolean;
  user: ClientManager;
};

const ClientManagerEditModalForm: FC<Props> = ({user, isClientManagerLoading}) => {
  const translate = useTranslate();
  const {setItemIdForUpdate} = useListView();
  const {refetch} = useQueryResponse();

  const [userForEdit] = useState<ClientManager>({
    id: user?.id || initialClientManager.id,
    first_name: user?.first_name || initialClientManager.first_name,
    last_name: user?.last_name || initialClientManager.last_name,
    email: user?.email || initialClientManager.email,
    phone: user?.phone || initialClientManager.phone,
    department_id: user?.department?.parent || initialClientManager.department_id,
    active: user?.active || initialClientManager.active,
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const closeError = () => {
    setHasErrors(undefined);
  };

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [errorMsj, setErrorMsj] = useState('');
  const [departments, setDepartments] = useState<Array<any>>([]);
  const [startOption, setStartOption] = useState('');

  const {handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid, values, setFieldValue} = useFormik({
    initialValues: userForEdit,
    validationSchema: dataSchema,
    onSubmit: async (valuesData, {setStatus, setSubmitting}) => {
      setSubmitting(true);

      try {
        let data: any;

        valuesData.phone = cleanPhoneNumber(valuesData.phone);
        if (values.id) {
          data = await updateClientManager(valuesData, values.id);
        } else {
          data = await createClientManager(valuesData);
        }

        setHasErrors(false);
        toast.success(data?.data?.message || '');
        cancel(true);
      } catch (error: any) {
        setErrorMsj(error?.response.data.message || 'error');
        setHasErrors(true);
        setStatus('AUTH.AXIOS:ERROR');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const formNotTouched = Object.keys(touched).length === 0;

  const deleteItem = useMutation(() => deleteClientManager(user.id), {
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message || '');
      cancel(true);
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.message || error?.message || '');
      cancel(true);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {data} = await getDepartmentsFetch(false);
        if (data.length > 0) {
          const optionsSelect = setDepartmentSelectOptions(data);

          setDepartments(optionsSelect);
          if (optionsSelect.length > 0) {
            const userHasValidDepartment = optionsSelect.find(
              (item) => item.value === user?.department?.parent?.toString()
            );
            if (userHasValidDepartment) setStartOption(userHasValidDepartment.value);
            else setStartOption('');
          }
        }
      } catch (error) {
        console.error('Error fetching department:', error);
      }
    };
    fetchData();
  }, []);

  const filterData = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    setStartOption(selectedOption);
    touched.department_id = true;
    setFieldValue('department_id', isNaN(Number(selectedOption)) ? 0 : Number(selectedOption));
  };

  return (
    <>
      <form
        id='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        className='form'
        onSubmit={handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div className='modal-body py-lg-4 px-lg-2'>
          {hasErrors && (
            <CustomAlert
              errorMessage={errorMsj}
              onClose={closeError}
              className='mb-lg-8 alert alert-danger'
              classContent='custom-alert-user'
            />
          )}

          <div className='fv-row mb-3'>
            <CustomInput
              name='first_name'
              placeholder={translate('CLIENT_MANAGER.FIRST_NAME')}
              type='text'
              errors={errors?.first_name ? translate(errors.first_name) : ''}
              touched={touched?.first_name}
              fieldProps={getFieldProps('first_name')}
              autoComplete='off'
              className='form-control d-flex align-items-center input-style'
              classContainer='custom-select-add-user'
            />
          </div>

          <div className='fv-row mb-3'>
            <CustomInput
              name='last_name'
              placeholder={translate('CLIENT_MANAGER.LAST_NAME')}
              type='text'
              errors={errors?.last_name ? translate(errors.last_name) : ''}
              touched={touched?.last_name}
              fieldProps={getFieldProps('last_name')}
              autoComplete='off'
              className='form-control d-flex align-items-center input-style'
              classContainer='custom-select-add-user'
            />
          </div>

          <div className='fv-row mb-3'>
            <CustomInput
              name='email'
              placeholder={translate('USERS.ADD-USER-EMAIL')}
              type='email'
              disabled={values.id ? true : false}
              icon='files-tablet'
              errors={errors?.email ? translate(errors.email) : ''}
              touched={touched?.email}
              fieldProps={getFieldProps('email')}
              autoComplete='off'
              className='form-control d-flex align-items-center input-style'
              classContainer='custom-select-add-user'
            />
          </div>

          <div className='fv-row mb-3'>
            <CustomInput
              name='phone'
              placeholder={translate('CLIENT_MANAGER.PHONE')}
              type='text'
              errors={errors?.phone ? translate(errors.phone) : ''}
              touched={touched?.phone}
              fieldProps={getFieldProps('phone')}
              autoComplete='off'
              className='form-control d-flex align-items-center input-style'
              classContainer='custom-select-add-user'
            />
          </div>

          <div className='fv-row mb-3 '>
            <CustomSelect
              name='department_id'
              className='form-control d-flex align-items-center input-style'
              icon='files-tablet'
              placeholder='select'
              options={[{value: '', option: translate('USERS.ADD-USER-DEPARTMENT')}, ...departments]}
              classContainer='custom-select-add-user'
              fieldProps={getFieldProps('department_id')}
              valueSelected={startOption.toString() || ''}
              onChange={filterData}
              errors={errors?.department_id ? translate(errors.department_id) : ''}
              touched={touched?.department_id}
            />
          </div>

          <div className='fv-row mb-3'>
            <CustomInput
              name='sub_department'
              placeholder={translate('CLIENT_MANAGER.SUB-DEPARTMENT')}
              type='text'
              errors={errors?.sub_department ? translate(errors.sub_department) : ''}
              touched={touched?.sub_department}
              fieldProps={getFieldProps('sub_department')}
              autoComplete='off'
              className='form-control d-flex align-items-center input-style'
              classContainer='custom-select-add-user'
            />
          </div>
        </div>

        <div className='modal-footer custom-border-top'>
          {!!values.id && (
            <div className='custom-padding-user custom-button-log'>
              <button
                type='button'
                className='btn btn-outline btn-outline-danger btn-active-light-danger border-0'
                onClick={() => deleteItem.mutate()}
                data-bs-dismiss='kt_modal_create_app'
              >
                {translate('USERS.ADD-BUTTON-DELETE')}
              </button>
            </div>
          )}
          <div className='custom-button-log-bt2'>
            <button
              type='submit'
              className='btn btn-success btn-sm'
              disabled={formNotTouched || isSubmitting || !isValid}
            >
              {translate('FORM-LOG-BUTTON4')}
            </button>
          </div>
          <div className='custom-button-log-bt3'>
            <button
              type='button'
              className='btn btn-light btn-sm'
              onClick={() => setItemIdForUpdate(undefined)}
              disabled={isSubmitting}
            >
              {translate('FORM-LOG-BUTTON3')}
            </button>
          </div>
        </div>

        {/* end::Actions */}
      </form>
      {(isSubmitting || isClientManagerLoading) && <ClientManagersListLoading />}
    </>
  );
};

export {ClientManagerEditModalForm};
