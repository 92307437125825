import {ID, Response} from '../../../../../../_metronic/helpers';
export type ClientManager = {
  id?: ID;
  email?: string;
  department?: any;
  department_id?: number;
  role?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  sub_department?: string;
  active?: boolean;
};

export type ClientManagersQueryResponse = Response<Array<ClientManager>>;

export const initialClientManager: ClientManager = {
  first_name: '',
  last_name: '',
  email: '',
  department_id: undefined,
  active: true,
};
