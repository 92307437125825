import * as Yup from 'yup'

export const initialValues = {
  date1: new Date().toISOString(),
  date2: new Date().toISOString(),
}


export const dataSchema = Yup.object().shape({
  date1: Yup.date()
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
  date2: Yup.date()
    .required('AUTH.VALIDATION.REQUIRED_FIELD')
});





export default dataSchema;


