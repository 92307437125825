import { toast } from "react-toastify";
import {  getRoles } from "../../core/_requests";

export const getRolesFetch = async () => {
    try {
        const data = await getRoles();
        return data?.data;
    } catch (error: any) {
      toast.error(error?.message || '');
      return null;
    }
  };

