import { useState, useEffect } from "react";
import moment, { Duration, Moment } from "moment";
import { TimeClockStatus } from "./useTimeclock";

function useTimer({ clockIn, clockOut, lunchStart, currentStatus, clockInDb, info }) {
  const [mainTime, setMainTime] = useState<Duration>(moment.duration(0));
  const [lunchTime, setLunchTime] = useState<Duration>(moment.duration(0));
  const [isMainTimerRunning, setIsMainTimerRunning] = useState(false);
  const [isLunchTimerRunning, setIsLunchTimerRunning] = useState(false);
  const [mainTimerStart, setMainTimerStart] = useState<Moment | null>(null);
  const [lunchTimerStart, setLunchTimerStart] = useState<Moment | null>(null);
  const [savedMainTime, setSavedMainTime] = useState<Duration>(moment.duration(0));
  const [prevStatus, setPrevStatus] = useState(null);

  // Effect to track the previous status
  useEffect(() => {
    setPrevStatus(currentStatus);
  }, [currentStatus]);

  // Effect to handle the main work timer
  useEffect(() => {
    if (isMainTimerRunning && mainTimerStart) {
      const interval = setInterval(() => {
        const elapsed = moment.duration(moment().diff(mainTimerStart));
        setMainTime(savedMainTime.clone().add(elapsed));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isMainTimerRunning, mainTimerStart, savedMainTime]);

  // Effect to handle the lunch timer
  useEffect(() => {
    if (isLunchTimerRunning && lunchTimerStart) {
      const interval = setInterval(() => {
        const elapsed = moment.duration(moment().diff(lunchTimerStart));
        setLunchTime(elapsed); // Only track the difference from when the lunch timer started
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isLunchTimerRunning, lunchTimerStart]);

  useEffect(() => {
    if (clockIn && prevStatus !== TimeClockStatus.IN_LUNCH) {
      let lunch = 0;
      if (info?.endLunch) {
        lunch = moment(info.endLunch).diff(info.startLunch);
      }
      const elapsedTime = moment.duration(moment().utc(true).diff(clockInDb)).subtract(lunch);
      setSavedMainTime(elapsedTime);
      setMainTimerStart(moment.utc());
      setIsMainTimerRunning(true);
    }
  }, [clockIn, clockInDb, info]);

  useEffect(() => {
    if (clockOut) {
      // Stop both timers and reset their states
      setIsMainTimerRunning(false); // Stop the main timer
      setIsLunchTimerRunning(false); // Stop the lunch timer
      setMainTime(moment.duration(0)); // Reset main time
      setLunchTime(moment.duration(0)); // Reset lunch time
      setSavedMainTime(moment.duration(0)); // Clear saved main time
      setMainTimerStart(null);
      setLunchTimerStart(null);
    }
  }, [clockOut]);

  useEffect(() => {
    if (lunchStart) {
      // Pause main timer, start lunch timer, save current main timer state
      setSavedMainTime(mainTime.clone()); // Save current main time
      setIsMainTimerRunning(false);
      setMainTimerStart(null);
      setLunchTimerStart(moment()); // Start lunch timer
      setIsLunchTimerRunning(true); // Set lunch timer as running
    }
  }, [lunchStart, mainTime]);

  // Handle when transitioning back to CLOCKED_IN from lunch
  useEffect(() => {
    if (prevStatus === TimeClockStatus.IN_LUNCH && currentStatus === TimeClockStatus.CLOCKED_IN) {
      // Stop lunch timer, resume main timer from saved time
      setMainTimerStart(moment.utc());
      setIsMainTimerRunning(true);
      setIsLunchTimerRunning(false);
      setMainTimerStart(moment()); // Start counting from the moment lunch ends, using the saved time
    }
  }, [currentStatus, prevStatus]);

  const formatDuration = (duration: Duration) =>
    `${String(duration.hours()).padStart(2, "0")}:${String(duration.minutes()).padStart(2, "0")}:${String(
      duration.seconds()
    ).padStart(2, "0")}`;

  return {
    mainTime: formatDuration(mainTime),
    lunchTime: formatDuration(lunchTime),
  };
}

export default useTimer;
