import React, { forwardRef } from "react";
import { CustomTextAreasProps } from "./types";
import clsx from "clsx";
import "./CustomTextArea.css";

const CustomTextArea = forwardRef<HTMLTextAreaElement, CustomTextAreasProps>(
  (
    {
      name,
      errors,
      fieldProps,
      touched,
      autoComplete,
      placeholder,
      rows = 3,
      icon,
      label,
      onTextChange,
      numIconSpansPass = 6,
      numIconSpansInput = 6,
      classNameLabel = "form-label fs-6 fw-bolder text-dark",
      className = "form-control d-flex align-items-center ",
      style = {},
      classContainer = "",
      classSize = "",
      onChange,
      onBlur,
      value,
      disabled = false,
    },
    ref
  ) => {
    console.log(ref);
    const onHandleChangeText = () => {
      if (onTextChange) {
        onTextChange(false);
      }
    };
    const spansPass: any[] = [];
    for (let i = 1; i <= numIconSpansPass; i++) {
      spansPass.push(<span key={i} className={`path${i}`}></span>);
    }
    const spansInput: any[] = [];
    for (let i = 1; i <= numIconSpansInput; i++) {
      spansInput.push(<span key={i} className={`path${i}`}></span>);
    }

    return (
      <div className={classContainer}>
        {label && <label className={` ${classNameLabel}`}>{label}</label>}
        <div className={`${className}`} style={style}>
          {icon && (
            <i className={`${icon} fs-3 me-2`} style={{ color: "#555555" }}>
              {spansInput}
            </i>
          )}
          {value ? (
            <textarea
              placeholder={placeholder}
              rows={rows}
              style={{ resize: "none" }}
              {...fieldProps}
              className={clsx(
                `bg-transparent w-100 form-control-plaintext p-0 ${classSize}`,
                { "is-invalid": touched && errors },
                {
                  "is-valid": touched && !errors,
                }
              )}
              name={name}
              autoComplete={autoComplete}
              onClick={onHandleChangeText}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={disabled}
              ref={ref}
            />
          ) : (
            <textarea
              placeholder={placeholder}
              rows={rows}
              style={{ resize: "none" }}
              {...fieldProps}
              className={clsx(
                `bg-transparent w-100 form-control-plaintext p-0 ${classSize}`,
                { "is-invalid": touched && errors },
                {
                  "is-valid": touched && !errors,
                }
              )}
              name={name}
              autoComplete={autoComplete}
              onClick={onHandleChangeText}
              disabled={disabled}
              ref={ref}
            />
          )}
        </div>
        {touched && errors && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>
              {errors}
            </span>
          </div>
        )}
      </div>
    );
  }
);

export default CustomTextArea;
