import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { isNotEmpty } from "../../../../../../_metronic/helpers";
import { initialUserLogs, UserLogs } from "../core/_models";
import { useListView } from "../core/ListViewProvider";
import { UsersListLoading } from "../components/loading/UsersListLoading";
import { createUserLog, deleteUserLog, updateUserLog } from "../core/_requests";
import { useQueryResponse } from "../core/QueryResponseProvider";
import CustomDataPicker from "../../../../../components/CustomDataPicker";
import { useTranslate } from "../../../../../function/translate";
import moment from "moment";
import dataSchema from "./constants";
import { toast } from "react-toastify";
import CustomAlert from "../../../../../components/CustomAlert";
import { convertAMPMto24Hours } from "../../../../../function/protectedRoute";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
type Props = {
  isUserLoading: boolean;
  user: UserLogs;
};

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  let { idParam } = useParams();
  const navigate = useNavigate();
  const [idUser, setIdUser] = useState(0);
  useEffect(() => {
    if (!idParam || idParam === undefined || isNaN(+idParam)) {
      navigate("/users");
    } else {
      setIdUser(+idParam);
    }
  }, [idParam, navigate]);
  const selectedIn = user?.clockIn ? new Date(user?.clockIn) : new Date();
  const utcDateIn = new Date(
    selectedIn.getUTCFullYear(),
    selectedIn.getUTCMonth(),
    selectedIn.getUTCDate(),
    selectedIn.getUTCHours(),
    selectedIn.getUTCMinutes(),
    selectedIn.getUTCSeconds()
  );
  const [clockInDate, setclockInDate] = useState(user?.clockIn ? utcDateIn : new Date());
  const handleDateInChanges = (date) => {
    setclockInDate(date);
    values.clockInDate = date;
    values.created_at = date;
  };

  const selectedOut = user?.clockOut ? new Date(user?.clockOut) : new Date();
  const utcDateOut = new Date(
    selectedOut.getUTCFullYear(),
    selectedOut.getUTCMonth(),
    selectedOut.getUTCDate(),
    selectedOut.getUTCHours(),
    selectedOut.getUTCMinutes(),
    selectedOut.getUTCSeconds()
  );
  const [clockOutDate, setclockOutDate] = useState(user?.clockOut ? utcDateOut : new Date());
  const handleDateOutChanges = (date) => {
    setclockOutDate(date);
    values.clockOutDate = date;
  };

  const selectedHourIn = user?.clockIn ? new Date(user?.clockIn) : new Date();
  const utcDateTimeIn = new Date(
    selectedHourIn.getUTCFullYear(),
    selectedHourIn.getUTCMonth(),
    selectedHourIn.getUTCDate(),
    selectedHourIn.getUTCHours(),
    selectedHourIn.getUTCMinutes(),
    selectedHourIn.getUTCSeconds()
  );
  const [selectedInOption, setSelectedInOption] = useState(user?.clockIn ? utcDateTimeIn : new Date());
  const handleTimeInChanges = (date) => {
    const newDate = moment(date).format("hh:mm A");
    setSelectedInOption(date);
    values.clockIn = newDate;
  };

  const selectedHourOut = user?.clockOut ? new Date(user?.clockOut) : new Date();
  const utcDateTimeOut = new Date(
    selectedHourOut.getUTCFullYear(),
    selectedHourOut.getUTCMonth(),
    selectedHourOut.getUTCDate(),
    selectedHourOut.getUTCHours(),
    selectedHourOut.getUTCMinutes(),
    selectedHourOut.getUTCSeconds()
  );
  const [selectedOutOption, setSelectedOutOption] = useState(user?.clockOut ? utcDateTimeOut : new Date());
  const handleTimeOutChanges = (date) => {
    const newDate = moment(date).format("hh:mm A");
    setSelectedOutOption(date);
    values.clockOut = newDate;
  };

  const selectedLunchIn = user?.startLunch ? new Date(user?.startLunch) : new Date();
  const utcDateLunchIn = new Date(
    selectedLunchIn.getUTCFullYear(),
    selectedLunchIn.getUTCMonth(),
    selectedLunchIn.getUTCDate(),
    selectedLunchIn.getUTCHours(),
    selectedLunchIn.getUTCMinutes(),
    selectedLunchIn.getUTCSeconds()
  );
  const [selectedLInOption, setSelectedLInOption] = useState(user?.startLunch ? utcDateLunchIn : "");
  const handleLunchInChanges = (date) => {
    const newDate = moment(date).format("hh:mm A");
    setSelectedLInOption(date);
    values.startLunch = newDate;
  };

  const selectedLunchOut = user?.endLunch ? new Date(user?.endLunch) : new Date();
  const utcDateLunchOut = new Date(
    selectedLunchOut.getUTCFullYear(),
    selectedLunchOut.getUTCMonth(),
    selectedLunchOut.getUTCDate(),
    selectedLunchOut.getUTCHours(),
    selectedLunchOut.getUTCMinutes(),
    selectedLunchOut.getUTCSeconds()
  );
  const [selectedLOutOption, setSelectedLOutOption] = useState(user?.endLunch ? utcDateLunchOut : "");
  const handleLunchOutChanges = (date) => {
    const newDate = moment(date).format("hh:mm A");
    setSelectedLOutOption(date);
    values.endLunch = newDate;
  };

  const translate = useTranslate();
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();

  const [userForEdit] = useState<UserLogs>({
    ...user,
    clockInDate: moment(user.clockIn).format("L") || moment().format("L"),
    clockIn: moment(user.clockIn).format("hh:mm A") || moment().format("hh:mm A"),
    clockOutDate: user.clockOut ? moment.utc(user.clockOut).format("L") : moment().format("L"),
    clockOut: moment(user.clockOut).format("hh:mm A") || moment().format("hh:mm A"),
    startLunch: user?.startLunch ? moment.utc(user?.startLunch).format("hh:mm A") : "" || "",
    endLunch: user?.endLunch ? moment.utc(user?.endLunch).format("hh:mm A") : "" || "",
    created_at: user.created_at || new Date().toString(),
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };
  const closeError = () => {
    setHasErrors(undefined);
  };

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [errorMsj, setErrorMsj] = useState("");
  const [closeModal, setCloseModal] = useState(false);

  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid, values } = useFormik({
    initialValues: userForEdit,
    validationSchema: dataSchema,
    onSubmit: async (valuesData, { setStatus, setSubmitting }) => {
      const id = valuesData?.id;
      const created =
        valuesData?.clockInDate !== user?.clockInDate
          ? moment(valuesData?.clockInDate).format("YYYY-MM-DD")
          : moment(valuesData?.clockInDate).utc().format("YYYY-MM-DD");

      const clockIn = `${moment(valuesData?.clockInDate).format("YYYY-MM-DD")}T${convertAMPMto24Hours(
        valuesData.clockIn
      )}:00.000Z`;
      const clockOut = `${moment(valuesData?.clockOutDate).format("YYYY-MM-DD")}T${convertAMPMto24Hours(
        valuesData.clockOut
      )}:00.000Z`;
      const startLunch =
        valuesData?.startLunch !== ""
          ? `${moment(valuesData?.clockInDate).format("YYYY-MM-DD")}T${convertAMPMto24Hours(
              valuesData.startLunch
            )}:00.000Z`
          : null;
      const endLunch =
        valuesData?.endLunch !== ""
          ? `${moment(valuesData?.clockInDate).format("YYYY-MM-DD")}T${convertAMPMto24Hours(
              valuesData.endLunch
            )}:00.000Z`
          : null;
      const date = `${created}T01:00:00.000Z`;
      try {
        let data: any;
        if (isNotEmpty(values.id)) {
          data = await updateUserLog({ timeClockId: id, clockIn, clockOut, startLunch, endLunch, date });
        } else {
          data = await createUserLog({ user: idUser, clockIn, clockOut, startLunch, endLunch, date });
        }
        setHasErrors(false);
        setLoading(false);
        toast.success(data?.data?.message || "");
        setSubmitting(true);
        cancel(true);
      } catch (error: any) {
        console.error("modal1", error, error.message);
        setCloseModal(true);
        setErrorMsj(error?.response.data.message || "error");
        toast.error(error?.response.data.message || "error");
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus("AUTH.AXIOS:ERROR");
      } finally {
        //setSubmitting(true)
        //cancel(true)
      }
    },
  });
  values.clockIn = moment(selectedInOption).format("hh:mm A");
  values.clockOut = moment(selectedOutOption).format("hh:mm A");

  const selectOptions: { value: string; option: string }[] = [];

  const startHour = 0;
  const startMinute = 0;
  const endHour = 23;
  const endMinute = 55;

  for (let hour = startHour; hour <= endHour; hour++) {
    for (let minute = startMinute; minute <= endMinute; minute += 5) {
      const formattedHour = hour > 12 ? hour - 12 : hour;
      const isPM = hour >= 12;
      const period = isPM ? "PM" : "AM";
      const formattedMinute = minute.toString().padStart(2, "0");
      const option = `${formattedHour}:${formattedMinute} ${period}`;
      selectOptions.push({ value: option, option: option });
    }
  }

  const deleteItem = useMutation(() => deleteUserLog(user.id), {
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message || "");
      cancel(true);
    },
    onError: (error: any) => {
      toast.success(error?.message || "");
      cancel(true);
    },
  });

  const today = new Date();
  return (
    <>
      <form
        id='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        className='form'
        onSubmit={handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div className='modal-body py-lg-4 px-lg-2'>
          {hasErrors === true && (
            <CustomAlert
              errorMessage={errorMsj}
              onClose={closeError}
              className='mb-lg-8 alert alert-danger'
              classContent='custom-alert'
            />
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>{translate("AUTH.FORGOT.ERROR.FIRST")}</div>
            </div>
          )}

          <div className='fv-row mb-3 '>
            <CustomDataPicker
              name='clockInDate'
              icon='calendar'
              label={translate("DATE-FROM-LOGIN")}
              placeholder='calendar'
              autoComplete='off'
              classNameLabel='custom-label-form '
              className='form-control d-flex align-items-center input-style'
              errors={errors?.clockInDate ? translate(errors.clockInDate) : ""}
              touched={touched?.clockInDate}
              selected={clockInDate}
              startDate={clockInDate}
              iconClass='fs-1 me-2'
              onChange={handleDateInChanges}
              maxDate={today}
            />
            <CustomDataPicker
              name='clockIn'
              label={translate("DATE-FROM-IN")}
              placeholder='calendar'
              autoComplete='off'
              icon='time'
              classNameLabel='custom-label-form '
              className='form-control d-flex align-items-center input-style'
              errors={errors?.clockIn ? translate(errors.clockIn) : ""}
              touched={touched?.clockIn}
              selected={selectedInOption}
              startDate={selectedInOption}
              onChange={handleTimeInChanges}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={1}
              iconClass='fs-1 me-2'
              maxDate={selectedOutOption}
              dateFormat='h:mm aa'
            />
          </div>

          <div className='fv-row mb-3 '>
            <CustomDataPicker
              name='startLunch'
              label={translate("DATE-FROM-START-LUNCH")}
              placeholder='calendar'
              autoComplete='off'
              icon='time'
              classNameLabel='custom-label-form '
              className='form-control d-flex align-items-center input-style'
              errors={errors?.startLunch ? translate(errors.startLunch) : ""}
              touched={touched?.startLunch}
              selected={selectedLInOption}
              startDate={selectedLInOption}
              onChange={handleLunchInChanges}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={1}
              iconClass='fs-1 me-2'
              maxDate={selectedOutOption}
              dateFormat='h:mm aa'
            />
            <CustomDataPicker
              name='endLunch'
              label={translate("DATE-FROM-END-LUNCH")}
              placeholder='calendar'
              autoComplete='off'
              icon='time'
              classNameLabel='custom-label-form '
              className='form-control d-flex align-items-center input-style'
              errors={errors?.endLunch ? translate(errors.endLunch) : ""}
              touched={touched?.endLunch}
              selected={selectedLOutOption}
              startDate={selectedLOutOption}
              onChange={handleLunchOutChanges}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={1}
              iconClass='fs-1 me-2'
              maxDate={selectedOutOption}
              dateFormat='h:mm aa'
            />
          </div>

          <div className='fv-row mb-3 '>
            <CustomDataPicker
              name='clockOutDate'
              icon='calendar'
              label={translate("DATE-FROM-LOGOUT")}
              placeholder='calendar'
              autoComplete='off'
              classNameLabel='custom-label-form '
              className='form-control d-flex align-items-center input-style'
              errors={errors?.clockOutDate ? translate(errors.clockOutDate) : ""}
              touched={touched?.clockOutDate}
              selected={clockOutDate}
              startDate={clockOutDate}
              iconClass='fs-1 me-2'
              onChange={handleDateOutChanges}
              maxDate={today}
            />
            <CustomDataPicker
              name='clockOut'
              label={translate("DATE-FROM-OUT")}
              placeholder='calendar'
              autoComplete='off'
              icon='time'
              classNameLabel='custom-label-form '
              className='form-control d-flex align-items-center input-style'
              errors={errors?.clockOut ? translate(errors.clockOut) : ""}
              touched={touched?.clockOut}
              selected={selectedOutOption}
              iconClass='fs-1 me-2'
              onChange={handleTimeOutChanges}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={1}
              dateFormat='h:mm aa'
            />
          </div>
        </div>

        <div className='modal-footer custom-border-top'>
          {values.id ? (
            <div className='custom-padding-log custom-button-log custom-button-log-bt-delete'>
              <button
                type='button'
                className='btn btn-outline btn-outline-danger btn-active-light-danger border-0'
                onClick={async () => await deleteItem.mutateAsync()}
                data-bs-dismiss='kt_modal_create_app'
              >
                {translate("FORM-LOG-BUTTON1")}
              </button>
            </div>
          ) : null}
          <div className='custom-button-log-bt2'>
            <button type='submit' className='btn btn-success btn-sm'>
              {translate("FORM-LOG-BUTTON2")}
            </button>
          </div>
          <div className='custom-button-log-bt3'>
            <button
              type='button'
              className='btn btn-light btn-sm'
              onClick={() => setItemIdForUpdate(undefined)}
              data-bs-dismiss='kt_modal_create_app'
            >
              {translate("FORM-LOG-BUTTON3")}
            </button>
          </div>
        </div>

        {/* end::Actions */}
      </form>
      {(isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  );
};

export { UserEditModalForm };
