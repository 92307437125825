import {useState} from 'react';
import {KTIcon, initialQueryState} from '../../../../../../../_metronic/helpers';
import {useTranslate} from '../../../../../../function/translate';
import {useListView} from '../../core/ListViewProvider';
import {useQueryRequest} from '../../core/QueryRequestProvider';
import {FindAppModal} from '../../findModal/findAppModal';
import {getExportTimeLog} from '../../core/_requests';
import {exportToExcel} from '../../../../../../function/excelHelper';
import moment from 'moment';
import ExportToExcelModal from '../../export-modal/ExportToExcelModal';
import {useModalHandlers} from '../../../../user-log-history/users-list/core/ModalUseProvider';

const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView();
  const openAddUserModal = () => {
    setItemIdForUpdate(null);
  };

  const translate = useTranslate();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const {open, setOpen} = useModalHandlers();
  return (
    <>
      <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
        <button
          type='button'
          className='btn btn-outline  btn-outline-success btn-active-light-success me-3 custom-button-export'
          onClick={() => setOpen(true)}
        >
          <KTIcon iconName='plus' className='fs-2' />
          {translate('BUTTON-ADMIN-EXPORT')}
        </button>

        <div className='custom-button-add'>
          <button type='button' className='btn btn-success ' onClick={openAddUserModal}>
            <KTIcon iconName='plus' className='fs-2' />
            {translate('BUTTON-ADMIN-LOGGED')}
          </button>
        </div>
        {/* end::Add user */}
        <FindAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
      </div>
      {open && <ExportToExcelModal />}
    </>
  );
};

export {UsersListToolbar};
