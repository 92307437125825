import * as Yup from 'yup'
import { emailRegex } from '../../../../../../constants/constants';

export const initialValues = {
  name:"",
  email:"",
  department:""
}


export const dataSchema = Yup.object().shape({
  name: Yup.string()
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
  email: Yup.string()
    .email('AUTH.VALIDATION.INVALID_EMAIL')    
    .matches(emailRegex, 'AUTH.VALIDATION.INVALID_EMAIL')
    .min(3, 'AUTH.VALIDATION.MIN_LENGTH')
    .max(50, 'AUTH.VALIDATION.MAX_LENGTH')
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
  department: Yup.string().required('AUTH.VALIDATION.REQUIRED_FIELD'),
  role: Yup.string().required('AUTH.VALIDATION.REQUIRED_FIELD'),
});





export default dataSchema;


