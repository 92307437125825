import * as Yup from 'yup'
import { emailRegex } from '../../../../constants/constants'

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('AUTH.VALIDATION.INVALID_EMAIL')
    .matches(emailRegex, 'AUTH.VALIDATION.INVALID_EMAIL')
    .min(3, 'AUTH.VALIDATION.MIN_LENGTH')
    .max(50, 'AUTH.VALIDATION.MAX_LENGTH')
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
  password: Yup.string()
    .min(3, 'AUTH.VALIDATION.MIN_LENGTH')
    .max(50, 'AUTH.VALIDATION.MAX_LENGTH')
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
})
export const initialValues = {
  email: '',
  password: '',
}
