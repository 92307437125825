import axios from 'axios';
import {ID} from '../../../../../../_metronic/helpers';
import {User, UserLogs} from './_models';

const API_URL = process.env.REACT_APP_THEME_API_URL;
const API_URL2 = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;

const getUsers = (query: string) => {
  return '';
};

const getUserById = async (id: ID) => {
  const data: any = await axios.get(`${API_URL2}/users/user-list/get/?userId=${id}`);
  return data.data;
};

const getUserClientFeedbacksById = async (id: string, query: string) => {
  const data: any = await axios.get(`${API_URL2}/client-feedback/by-user/${id}?${query}`);
  return data.data;
};

const createUser = (user: User) => {};

const updateUser = (user: User) => {};

const deleteUser = (userId: ID) => {};

export const getTimelogsHistoryData = async (query: string, id) => {
  const data: any = await axios.get(`${API_URL2}/timelog/admin/users-logs?${query}&userId=${id}`);
  const dataResponse = data.data;

  return dataResponse;
};

export const getExportTimeLog = async () => {
  const data: any = await axios.get(`${API_URL2}/timelog/admin/export-users-logs`);
  console.log(data)
  const dataResponse = data.data.data.data;

  return dataResponse;
};

export const getTimelogs = async (id: ID) => {
  const data: any = await axios.get(`${API_URL2}/timelog/${id}`);
  const dataResponse: UserLogs = data.data;

  return dataResponse;
};

export const updateUserLog = async (user: any) => {
  const data: any = await axios.patch(`${API_URL2}/timelog/update-time-clock`, user);

  return data;
};

export const createUserLog = async (user: any) => {
  const data: any = await axios.post(`${API_URL2}/timelog/add-time-clock`, user);

  return data;
};

export const deleteUserLog = async (id: any) => {
  const data: any = await axios.delete(`${API_URL2}/timelog/delete/${id}`);

  return data;
};

export {createUser, deleteUser, getUserById, getUserClientFeedbacksById, getUsers, updateUser};
