/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {KTIcon} from '../../../../../../../_metronic/helpers'

const ClientManagersListPagination = () => {
  const pagination: any = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | undefined | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination my-4'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.page === 1,
              })}
            >
              <a onClick={() => updatePage(pagination.page - 1)} style={{cursor: 'pointer'}} className='page-link'>
                <KTIcon iconName={'left'} className='fs-2' />
              </a>
            </li>
            {Array.from(Array(pagination.total_pages).keys())
              ?.map((_, index) => index + 1)
              .map((page) => (
                <li
                  key={page}
                  className={clsx('page-item', {
                    active: pagination.page === page,
                    disabled: isLoading,
                    previous: page - 1 === pagination.page,
                    next: page + 1 === pagination.page,
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': page - 1 === pagination.page || page + 1 === pagination.page,
                    })}
                    onClick={() => updatePage(page)}
                    style={{cursor: 'pointer'}}
                  >
                    {page}
                  </a>
                </li>
              ))}
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.page === pagination?.total_pages,
              })}
            >
              <a onClick={() => updatePage(pagination.page + 1)} style={{cursor: 'pointer'}} className='page-link'>
                <KTIcon iconName={'right'} className='fs-2' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ClientManagersListPagination}
