import {useTranslate} from '../../../../../../function/translate';
import {useListView} from '../../core/ListViewProvider';
import {ClientFeedbackListToolbar} from './ClientFeedbackListToolbar';

const ClientFeedbackListHeader = () => {
  const {selected} = useListView();
  const translate = useTranslate();
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1 custom-table-logs-title'>
          {translate('USERS.TABLE-FEEDBACK-TITLE')}
        </div>
        {/* end::Search */}
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<ClientFeedbackListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export {ClientFeedbackListHeader};
