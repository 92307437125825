import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialUser, User} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import { createUser, deleteUserData, update} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import CustomDataPicker from '../../../../../components/CustomDataPicker'
import { useTranslate } from '../../../../../function/translate'
import moment from 'moment'
import dataSchema from './constants'
import { toast } from 'react-toastify'
import CustomAlert from '../../../../../components/CustomAlert'
import { convertAMPMto24Hours } from '../../../../../function/protectedRoute'
import { useMutation } from 'react-query'
import { getDepartmentsFetch } from './hooks/getDepartments'
import { useNavigate } from 'react-router-dom'
import CustomSelect from '../../../../../components/CustomSelect'
import CustomInput from '../../../../../components/CustomInput'
import { emailRegex } from '../../../../../constants/constants'
import { getRolesFetch } from './hooks/getRoles'
import { setDepartmentSelectOptions, setSelectOptions } from '../../../../../function/generalHelper'
type Props = {
  isUserLoading: boolean
  user: User
}


const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {


  const translate = useTranslate();
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  let nameEdit;
  
  if(user?.first_name  || user?.last_name){
    nameEdit = user?.first_name +" " + user?.last_name;
  }
  const [userForEdit] = useState<User>({
    ...user,
    name: nameEdit  || initialUser.name,
    email: user.email  || initialUser.email,
    department: user.department || initialUser.department,
    role: user.role || initialUser.role,
  })
  

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const closeError = () => {
    setHasErrors(undefined);
  };
  const validate = (values) => {
    const errors = {email: ''};
  
    if (!values.email) {
      errors.email = 'AUTH.VALIDATION.REQUIRED_FIELD';
    } else if (emailRegex.test(values.email)) {
      errors.email = 'AUTH.VALIDATION.INVALID_EMAI';
    }
  
    return errors;
  };

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMsj, setErrorMsj] = useState('')
  const [closeModal, setCloseModal] = useState(false)

  const { handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid, values, handleChange, handleBlur } = useFormik({
    initialValues: userForEdit,
    validationSchema: dataSchema,
    onSubmit: async (valuesData, {setStatus, setSubmitting}) => {
      try {
        let data: any;
        const fullName = valuesData.name;
        const nameParts = fullName ? fullName.split(" ") : [];
        let firstName = "";
        let lastName ='';
        if (Array.isArray(nameParts) && nameParts.length === 1) {
          if (Array.isArray(nameParts)) {
            const last_nameData = nameParts?.pop();
            firstName = last_nameData? last_nameData: '';
          }  
        }

        if (Array.isArray(nameParts) && nameParts.length > 1) {
          if (Array.isArray(nameParts)) {
            const last_nameData = nameParts?.pop();
            lastName = last_nameData? last_nameData: '';
          }        
          firstName = nameParts.join(" ");
        }
        const dataToSend = {email: valuesData.email, first_name: firstName, 
          last_name: lastName, role: valuesData.role?+valuesData.role: null, departments: valuesData.department?+valuesData.department: null };

        if (isNotEmpty(values.id)) {
          data = await update(dataToSend, values.id)
        } else{
          data = await createUser(dataToSend)
        }
        setHasErrors(false)
        setLoading(false)
        toast.success(data?.data?.message ||'')
        setSubmitting(true)
        cancel(true)
      } catch (error : any) {
        setCloseModal(true);
        setErrorMsj(error?.response.data.message || "error");
        toast.error(error?.response.data.message || "error")
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('AUTH.AXIOS:ERROR')
      } finally {
        //setSubmitting(true)
        //cancel(true)
        
      }
      
    },
  })

    const deleteItem = useMutation(() => deleteUserData(user.id), {
      onSuccess: async (data) => {
        // ✅ update detail view directly
        toast.success(data?.data?.message ||'')
        cancel(true)
        
      },
      onError: (error: any) => {
        toast.success(error?.message ||'')
        cancel(true)
      },
    })

    
    
    const navigate = useNavigate();
    
  
    
    const [department, setDepartment] = useState<Array<any>>([])
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (department.length === 0) {
            const departments = await getDepartmentsFetch();
            if (departments.length !== 0) { 
              const optionsSelect = setDepartmentSelectOptions(departments.data);
              setDepartment(optionsSelect)
            }
          }
        } catch (error) {
          console.error("Error fetching department:", error);
        }
      };
      fetchData();
    }, [department]);
    const [startOption, setStartOption] = useState(user.departments ? user.departments : '')
    useEffect(() => {
      if(user.departments){
        values.department= user.departments;
      }
    }, []);
    const filterData = (e) => {
      const selectedOption = e.target.value;
      
      setStartOption(selectedOption);
      
      values.department = selectedOption;
    }

    const [roles, setDRoles] = useState([])
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (roles.length === 0) {
            const dataRoles = await getRolesFetch();
            if (dataRoles.length !== 0) { 
              const optionsSelect = setSelectOptions(dataRoles);
              setDRoles(optionsSelect)
            }
          }
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      };
      fetchData();
    }, [roles]);
    
    const [startOptionRoles, setStartOptionRoles] = useState(user.role ? user.role : '')
    
    useEffect(() => {
      if(user.role){
        values.role= user.role;
      };
    }, []);
    const filterDataRoles = (e) => {
      const selectedOption = e.target.value;
      setStartOptionRoles(selectedOption);
      
      values.role = selectedOption;
    }
   

  return (
    <>
      <form id='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' className='form' onSubmit={handleSubmit} noValidate>
        {/* begin::Scroll */}
          <div className='modal-body py-lg-4 px-lg-2'>
          {hasErrors === true && (
          <CustomAlert errorMessage={errorMsj} onClose={closeError} className='mb-lg-8 alert alert-danger' classContent='custom-alert-user'/>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>{translate('AUTH.FORGOT.ERROR.FIRST')}
                </div>
              </div>
            )}

            

              
              <div className='fv-row mb-3'>
                <CustomInput
                  name='name'
                  placeholder={translate('USERS.ADD-USER-NAME')}
                  type='test'
                  errors={errors?.name ? translate(errors.name) : ''}
                  touched={touched?.name} 
                  fieldProps={getFieldProps('name')}
                  autoComplete='off'
                  className= 'form-control d-flex align-items-center input-style'
                  classContainer='custom-select-add-user'
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // value={values.name}
                />
              </div>

              <div className='fv-row mb-3'>
                <CustomInput
                  name='email'
                  placeholder={translate('USERS.ADD-USER-EMAIL')}
                  type='email'
                  disabled= {values.id? true: false}
                  icon='files-tablet'
                  errors={errors?.email ? translate(errors.email) : ''}
                  touched={touched?.email} 
                  fieldProps={getFieldProps('email')}
                  autoComplete='off'                  
                  className= 'form-control d-flex align-items-center input-style'
                  classContainer='custom-select-add-user'
                  
                />
              </div>

              <div className='fv-row mb-3 '>
                <CustomSelect
                  name='department'
                  className= 'form-control d-flex align-items-center input-style'
                  icon="files-tablet"
                  placeholder='select'
                  options={[{ value: '', option: translate('USERS.ADD-USER-DEPARTMENT') },...department]}
                  classContainer='custom-select-add-user'
                  fieldProps={getFieldProps('department')}
                  valueSelected ={startOption}
                  onChange={filterData}
                  errors={errors?.department ? translate(errors.department) : ''}
                  touched={touched?.department} 
                />
                
              </div>

              <div className='fv-row mb-3 '>
                <CustomSelect
                  name='role'
                  className= 'form-control d-flex align-items-center input-style'
                  icon="star"
                  placeholder='select'
                  options={[{ value: '', option: translate('USERS.ADD-USER-ROLES') },...roles]}
                  classContainer='custom-select-add-user'
                  fieldProps={getFieldProps('role')}
                  valueSelected ={startOptionRoles}
                  onChange={filterDataRoles}
                  errors={errors?.role ? translate(errors.role) : ''}
                  touched={touched?.role} 
                />
                
              </div>


              </div>

              <div className="modal-footer custom-border-top">
              {values.id ? (
                  <div className='custom-padding-user custom-button-log'>
                    <button
                      type="button"
                      className="btn btn-outline btn-outline-danger btn-active-light-danger border-0"
                      onClick={async () => await deleteItem.mutateAsync()}
                      data-bs-dismiss="kt_modal_create_app"
                    >
                      {translate('USERS.ADD-BUTTON-DELETE')}
                    </button>
                  </div>
                ) : null}
                <div className='custom-button-log-bt2'>
                {!values.id ? (
                <button type='submit' className="btn btn-success btn-sm">{translate('USERS.ADD-BUTTON-ADD')}</button>): 
                (<button type='submit' className="btn btn-success btn-sm">{translate('USERS.ADD-BUTTON-ADD-2')}</button>)}
                </div>
                <div className='custom-button-log-bt3'>
                <button type="button" className="btn btn-light btn-sm" onClick={() => setItemIdForUpdate(undefined)} data-bs-dismiss="kt_modal_create_app">{translate('FORM-LOG-BUTTON3')}</button>
                </div>   
                
                        
                        
              </div>
              
        {/* end::Actions */}
      </form>
      {(isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}
