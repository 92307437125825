import {Link, useNavigate} from 'react-router-dom'
import { useForgotPassword } from './hooks/useForgotPassword'
import CustomInput from '../../../../components/CustomInput'
import CustomAlert from '../../../../components/CustomAlert';
import {useIntl} from 'react-intl'

export function ForgotPassword() {
  const {
    errors,
    getFieldProps,
    handleSubmit,
    hasErrors,
    errorMsj,
    setHasErrors,
    isSubmitting,
    isValid,
    loading,
    touched
  } = useForgotPassword()
  const closeError = () => {
    setHasErrors(undefined);
  };
  const intl = useIntl()
  const navigate = useNavigate()
  const handleRedirect = () => {
    navigate(`/auth/login`)
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-start mb-10 text-dark fw-bolder'>
        <div className='custom-text-forgot'>
        {intl.formatMessage({id: 'AUTH.FORGOT.TITLE-FORGOT'})}
        </div>
        {/* begin::Title */}
        {/* end::Title */}

        {/* begin::Link */}
        <div className='custom-text-2-forgot'>
          {intl.formatMessage({id: 'AUTH.FORGOT.DESC'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <CustomAlert errorMessage={errorMsj} onClose={closeError} />
      )}

      {hasErrors === false && (
        <div className='mb-5 bg-light-info p-8 rounded'>
          <div className='text-info'>{intl.formatMessage({id: 'AUTH.FORGOT.ERROR.FIRST'})}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-12'>
        <CustomInput
          name='email'
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          type='email'
          touched={touched.email}
          errors={errors?.email ? intl.formatMessage({ id: errors.email }) : ''}
          fieldProps={getFieldProps('email')}
          autoComplete='off'
          icon="ki-duotone ki-user"
          className= 'form-control d-flex align-items-center input-style'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='container-button d-grid mb-5 custom-button'>
      <button type='submit' id='kt_password_reset_submit' className='btn btn-success' style={{color: 'white'}}>
          <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.GENERAL.SEND.CODE'})}</span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'GENERAL.PROGRESS'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      
      <div className='container-button d-grid mb-10 container-button-light'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            onClick={handleRedirect}
            style={{color: '#989898'}}
          >
            {intl.formatMessage({id: 'GENERAL.CANCEL.BUTTON'})}
          </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
