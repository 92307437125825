import {Navigate, Outlet} from 'react-router-dom'
import {optionsRoles} from '../constants/routes.roles'

export const ProtectedRoute = ({userRole = '', module = '', redirectTo = '/user-logs', children = null}) => {
  const allowedRoles = optionsRoles[module] || []
  const redirectToPath = userRole === 'Director' || userRole === 'Manager' ? '/users' : redirectTo
  if (allowedRoles.length === 0 || allowedRoles.includes(userRole)) {
    return children || <Outlet />
  }
  return <Navigate to={redirectToPath} replace />
}
