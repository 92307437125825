import * as Yup from 'yup'

export const initialValues = {
  code: '',
}

export const verificationCodeSchema = Yup.object().shape({
  code: Yup.number()
    .required('AUTH.VERIFICATION.CODE.VALIDATION.FIST')
    .min(5, 'AUTH.VERIFICATION.CODE.VALIDATION.SECOND'),
})