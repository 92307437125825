import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useModalHandlers } from "../../../user-log-history/users-list/core/ModalUseProvider";
import CustomDataPicker from "../../../../../components/CustomDataPicker";
import { useTranslate } from "../../../../../function/translate";
import moment from "moment";
import CustomSelect from "../../../../../components/CustomSelect";
import { getAllDepartmentsFetch } from "../../../client-managers/client-managers-list/user-edit-modal/hooks/getDepartments";
import { setDepartmentSelectOptions } from "../../../../../function/generalHelper";
import { exportToExcel } from "../../../../../function/excelHelper";
import { getExportTimeLog } from "../core/_requests";

function ExportToExcelModal({ isMainMenu }: { isMainMenu?: boolean }) {
  const translate = useTranslate();
  const { setOpen } = useModalHandlers();
  const [startDate, setStartDate] = useState("");
  const today = moment().toDate();
  const [endDate, setEndDate] = useState(today);
  const [startOption, setStartOption] = useState("All");
  const [exportAll, setExportAll] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Array<any>>([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  useEffect(() => {
    /* Used for handling startDate */
    const today = moment();
    let start;

    if (today.date() < 15) {
      start = today.startOf("month"); // 1st of the month
    } else {
      start = today.startOf("month").date(15); // 15th of the month
    }

    setStartDate(start.toDate());
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await getAllDepartmentsFetch();
      if (data.length > 0) {
        const optionsSelect = setDepartmentSelectOptions(data);
        setDepartments(optionsSelect);
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  const filterData = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    setStartOption(selectedOption);
    setSelectedDepartment(selectedOption);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleExportLogs = async () => {
    try {
      const filters: any = {
        filter_date1: moment(startDate).format("YYYY-MM-DD"),
        filter_date2: moment(endDate).format("YYYY-MM-DD"),
        filter_type: startOption,
        exportAll,
      };

      if (!exportAll) {
        filters.departmentId = selectedDepartment; // Add department filter only if exportAll is false
      }

      let logs = await getExportTimeLog(filters);
      if (logs && logs.length > 0) {
        logs = logs.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return new Date(a.clockIn).getTime() - new Date(b.clockIn).getTime();
        });
        console.log(logs, "logs xd");
        logs = logs.map((log) => ({
          ...log,
          clockIn: log.clockIn ? moment(log.clockIn).utc().format("MM/DD/YYYY HH:mm").toLowerCase() : "",
          clockOut: log.clockOut ? moment(log.clockOut).utc().format("MM/DD/YYYY HH:mm").toLowerCase() : "",
          startLunch: log.startLunch ? moment(log.startLunch).utc().format("MM/DD/YYYY HH:mm").toLowerCase() : "",
          endLunch: log.endLunch ? moment(log.endLunch).utc().format("MM/DD/YYYY HH:mm").toLowerCase() : "",
        }));

        exportToExcel(logs, "Users_Timelog", "timeclock_log_template.xlsx", "A6");
      } else {
        console.error("No logs to export");
      }
    } catch (error) {
      console.error("Error exporting logs:", error);
    }
  };

  return (
    <>
      <div className='modal fade show d-block ' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-800px align-items-center'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div
              className='modal-header'
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: 0,
                paddingBottom: "10px",
              }}
            >
              {/* begin::Close */}
              <div
                className='modal-header'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: 0,
                  paddingBottom: "10px",
                  width: "100%",
                }}
              >
                {/* begin::Modal title */}
                <div>
                  <div className='custom-title-add'>{translate("BUTTON-ADMIN-EXPORT")}</div>
                </div>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setOpen(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <KTIcon iconName='abstract-11' iconType='solid' className='fs-1' />
                  </div>
                  <div style={{ height: "45px" }}></div>
                </div>
                {/* end::Close */}
              </div>
            </div>

            {/* begin::Modal body */}
            <div className='mb-5' style={{ paddingRight: 15, paddingLeft: 15 }}>
              <div className='fv-row mb-5 '>
                <CustomDataPicker
                  name='clockOutDate'
                  icon='calendar'
                  label={translate("ADMIN_EXPORT_START")}
                  placeholder='calendar'
                  autoComplete='off'
                  classNameLabel='custom-label-form '
                  className='form-control d-flex align-items-center input-style'
                  selected={startDate}
                  startDate={startDate}
                  iconClass='fs-1 me-2'
                  onChange={handleStartDateChange}
                  maxDate={today}
                />
                <CustomDataPicker
                  name='clockOutDate'
                  icon='calendar'
                  label={translate("ADMIN_EXPORT_END")}
                  placeholder='calendar'
                  autoComplete='off'
                  classNameLabel='custom-label-form '
                  className='form-control d-flex align-items-center input-style'
                  selected={endDate}
                  endDate={endDate}
                  iconClass='fs-1 me-2'
                  onChange={handleEndDateChange}
                  maxDate={today}
                />
              </div>
              <div className='mb-5'>
                <CustomSelect
                  label='Filter by department'
                  name='department'
                  className='form-control d-flex align-items-center input-style'
                  icon='files-tablet'
                  placeholder='select'
                  options={[{ value: "", option: translate("USERS.ADD-USER-DEPARTMENT") }, ...departments]}
                  classContainer='custom-select-add-user'
                  valueSelected={startOption}
                  onChange={filterData}
                  disabled={exportAll}
                />
              </div>
              <div className='mb-5 d-flex align-items-center gap-2' style={{ paddingLeft: 15 }}>
                <label htmlFor='all' className='ms-5 me-5' style={{ fontSize: 16 }}>
                  Export all
                </label>
                <input
                  type='checkbox'
                  style={{
                    width: "15px",
                    height: "15px",
                    transform: "scale(1.1)",
                    transformOrigin: "center",
                    cursor: "pointer",
                  }}
                  checked={exportAll}
                  id='all'
                  onChange={() => setExportAll((prev) => !prev)}
                />
              </div>
              <button
                type='submit'
                className='btn btn-sm btn-primary mb-5'
                style={{ marginLeft: 30 }}
                data-kt-menu-dismiss='true'
                onClick={() => handleExportLogs()}
              >
                {translate("BUTTON-ADMIN-EXPORT")}
              </button>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  );
}

export default ExportToExcelModal;
