import { useQuery } from "react-query";
import { useAuth } from "../../../../../../../app/modules/auth";
import { getTodayStatus, setClockIn, setClockOut, setStartLunch, setEndLunch } from "../services/time-clock.service";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { useTimelogs } from "../../../../../../../app/pages/dashboard/hooks/useTimelogs";
import { useTranslate } from "../../../../../../../app/function/translate";

export enum TimeClockStatus {
  CLOCKED_IN = "DASHBOARD-USER-SIGNED-IN",
  CLOCKED_OUT = "DASHBOARD-USER-SIGNED-OUT",
  NO_LOGGED = "DASHBOARD-USER-NOT-LOGGED",
  IN_LUNCH = "DASHBOARD-USER-IN-LUNCH",
}

export const useTimeclock = (currentPeriod?: string | null) => {
  const { refetchTable } = useTimelogs(currentPeriod);
  const { currentUser } = useAuth();
  const [currentStatus, setCurrentStatus] = useState<TimeClockStatus>(TimeClockStatus.NO_LOGGED);
  const translate = useTranslate();

  const stateLabel = useMemo(() => {
    if (currentStatus === TimeClockStatus.CLOCKED_IN || currentStatus === TimeClockStatus.IN_LUNCH)
      return translate("DASHBOARD-USER-CLOCK-OUT-BUTTON");
    if (currentStatus === TimeClockStatus.NO_LOGGED) return translate("DASHBOARD-USER-CLOCK-IN-BUTTON");
    return translate("DASHBOARD-USER-CLOCK-IN-BUTTON");
  }, [currentStatus]);

  const stateLunchLabel = useMemo(() => {
    if (currentStatus === TimeClockStatus.IN_LUNCH) return translate("DASHBOARD-USER-END-LUNCH-BUTTON");
    return translate("DASHBOARD-USER-START-LUNCH-BUTTON");
  }, [currentStatus]);

  const {
    data: resp,
    status,
    refetch,
  } = useQuery({
    queryKey: "timeclockStatus",
    queryFn: () => getTodayStatus(currentUser?.id),
  });

  useEffect(() => {
    if (status !== "success") return;

    const { data } = resp as AxiosResponse<any>;

    const timelog = data?.data?.data;

    if (timelog?.length === 0) {
      setCurrentStatus(TimeClockStatus.NO_LOGGED);
      return;
    }

    if (timelog[0]?.startLunch !== null && timelog[0]?.endLunch === null) {
      setCurrentStatus(TimeClockStatus.IN_LUNCH);
      return;
    }

    if (timelog[0]?.clockOut === null && timelog[0]?.clockIn !== null) {
      setCurrentStatus(TimeClockStatus.CLOCKED_IN);
      return;
    }

    if (timelog[0]?.clockOut !== null && timelog[0]?.clockIn !== null) {
      setCurrentStatus(TimeClockStatus.NO_LOGGED);
      return;
    }
  }, [status, resp]);

  const createTimelog = async () => {
    if (currentStatus === TimeClockStatus.NO_LOGGED) {
      try {
        await setClockIn(currentUser?.id);
        setCurrentStatus(TimeClockStatus.CLOCKED_IN);
        toast.success(`You have clocked in successfully`);
      } catch (error) {}
    }

    if (currentStatus === TimeClockStatus.CLOCKED_IN) {
      try {
        await setClockOut(currentUser?.id);
        setCurrentStatus(TimeClockStatus.NO_LOGGED);
        toast.success("You have clocked out successfully");
      } catch (error) {}
    }

    refetch();
    refetchTable();
  };

  const createLunchLog = async () => {
    if (currentStatus === TimeClockStatus.CLOCKED_IN) {
      try {
        await setStartLunch(currentUser?.id);
        setCurrentStatus(TimeClockStatus.IN_LUNCH);
        toast.success(`You have started lunch successfully`);
      } catch (error) {}
    }
    if (currentStatus === TimeClockStatus.IN_LUNCH) {
      try {
        await setEndLunch(currentUser?.id);
        setCurrentStatus(TimeClockStatus.CLOCKED_IN);
        toast.success(`You have ended lunch successfully`);
      } catch (error) {}
    }
    refetch();
    refetchTable();
  };
  let responseData;
  if (typeof resp === "object" && "data" in resp) {
    responseData = resp.data?.data?.data;
  } else {
    responseData = null; // Handle the case where it's a string or number
  }

  return {
    data: responseData,
    currentStatus,
    createTimelog,
    createLunchLog,
    stateLabel,
    stateLunchLabel,
  };
};
