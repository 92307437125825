import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_TODAY_TIMELOG = `${API_URL}/timelog/all`;

export const getTimelogsHistory = async (userId?: string | number, currentPeriod?: string | null) => {
  const data = await axios.get(
    `${API_URL}/timelog/all/history?user=${userId}${currentPeriod ? `&currentPeriod=${currentPeriod}` : ''}`
  );
  return data;
};
