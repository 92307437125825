/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from '../../../../../../_metronic/helpers';
import {useQueryRequest} from './QueryRequestProvider';
import {ClientFeedback} from './_models';
import {getUserClientFeedbacksById} from './_requests';

const QueryResponseContext = createResponseContext<ClientFeedback>(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest();
  let {idParam} = useParams();

  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USER_CLIENT_FEEDBACKS}-${query}`,
    () => {
      if (!idParam || idParam === undefined || isNaN(+idParam)) {
        return null;
      }
      return getUserClientFeedbacksById(idParam, query);
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  );

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const {response} = useQueryResponse();
  if (!response) return [];

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = initialQueryState;

  const {response} = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
