/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl';
import {validateRouteAccess} from '../../../../../app/function/protectedRoute';
import {useAuth} from '../../../../../app/modules/auth';
import {SidebarMenuItem} from './SidebarMenuItem';

const SidebarMenuMain = () => {
  const intl = useIntl();
  const {currentUser} = useAuth();

  return (
    <>
      {/* <SidebarMenuItem
        to='dashboard'
        icon='rescue'
        title={intl.formatMessage({id: 'MENU.DASHBOARD-MENU'})}
        fontIcon='bi-app-indicator'
      /> */}

      {validateRouteAccess({userRole: currentUser?.role?.name, module: 'timelog'}) && (
        <SidebarMenuItem
          to='user-logs'
          icon='time'
          title={intl.formatMessage({id: 'MENU.USER-LOGS'})}
          fontIcon='bi-app-indicator'
        />
      )}

      {validateRouteAccess({userRole: currentUser?.role?.name, module: 'users'}) && (
        <SidebarMenuItem
          to='/users'
          icon='profile-user'
          title={intl.formatMessage({id: 'MENU.USER'})}
          fontIcon='bi-layers'
        />
      )}

      {validateRouteAccess({userRole: currentUser?.role?.name, module: 'clientManagers'}) && (
        <SidebarMenuItem
          to='/client-managers'
          icon='profile-circle'
          title={intl.formatMessage({id: 'MENU.CLIENT-MANAGERS'})}
          fontIcon='bi-layers'
        />
      )}
    </>
  );
};

export {SidebarMenuMain};
