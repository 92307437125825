import { FC, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { KTCard, KTCardBody, toAbsoluteUrl } from "../../../_metronic/helpers";
import { useTimelogs } from "./hooks/useTimelogs";
import { useTranslate } from "../../function/translate";
import { CustomHeaderColumn } from "./components/CustomHeaderColumn";
import { CustomRow } from "./components/CustomRow";
import { UsersListLoading } from "../../modules/apps/user-management/users-list/components/loading/UsersListLoading";
import { useLocation } from "react-router-dom";
import { UsersListHeader } from "./UsersListHeader";
import { useModalHandlers } from "../../modules/apps/user-log-history/users-list/core/ModalUseProvider";
import ReviewModal from "../../modules/apps/user-management/users-list/reviewModal/ReviewModal";

const DashboardWrapper: FC = () => {
  const [period, setPeriod] = useState<boolean>(true);

  const { greating, headers, getTableProps, getTableBodyProps, rows, prepareRow, isLoading, refetchTable } =
    useTimelogs(period ? "current" : "past");
  const translate = useTranslate();
  const location = useLocation();
  const { open } = useModalHandlers();

  const handleReviewSubmitted = () => {
    refetchTable();
  };

  return (
    <>
      <KTCard>
        <div style={{ color: "black" }}>
          <PageTitle breadcrumbs={[]} imageUri={toAbsoluteUrl("/media/sun-icon.svg")}>
            {greating}
          </PageTitle>
        </div>
        {/user-logs/.test(location.pathname) ? (
          <>
            <UsersListHeader period={period} setPeriod={setPeriod} />
            <div className='separator my-2'></div>
            <KTCardBody className='py-4'>
              <div className='table-responsive'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fw-400 gy-5 dataTable no-footer'
                  style={{ fontSize: "14px", lineHeight: "22.41px" }}
                  {...getTableProps()}
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      {headers.map((column) => (
                        <CustomHeaderColumn key={column.id} column={column} />
                      ))}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold' style={{ fontSize: "14px" }} {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                      rows.map((row, i) => {
                        prepareRow(row);
                        return <CustomRow row={row} key={`row-${i}-${row.id}`} hasReviews={true} />;
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            {translate("DASHBOARD-TABLE-NOT-MACH")}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <UsersListPagination /> */}
              {isLoading && <UsersListLoading />}
            </KTCardBody>
          </>
        ) : null}
      </KTCard>
      {open && <ReviewModal onReviewSubmitted={handleReviewSubmitted} />}
    </>
  );
};

export { DashboardWrapper };
