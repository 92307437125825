import {FC} from 'react'
import moment from 'moment';

type Props = {
  time: any
}

const UserTimeLogHour: FC<Props> = ({ time }) => (
  <div className='flex-column custom-table-data' style={{ padding: '0px', display: 'inline-block' }}>
    {moment.utc(time).format('hh:mm A')}
  </div>
)

export {UserTimeLogHour}
