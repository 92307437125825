import {FC} from 'react'
import moment from 'moment';

type Props = {
  departments: any
}

const UserDepartment: FC<Props> = ({ departments }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column custom-table-data'>
      {departments?.name}
    </div>
  </div>
)

export {UserDepartment}
