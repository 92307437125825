import _focusAuthImg1 from '../../_metronic/assets/images/auth/img-1.jpg';
import _focusAuthImg2 from '../../_metronic/assets/images/auth/img-2.png';
import _focusAuthImg3 from '../../_metronic/assets/images/auth/img-3.png';
import _focusAuthImg4 from '../../_metronic/assets/images/auth/img-4.jpg';
import _focusItoImgWhite from '../../_metronic/assets/images/focus-ito-white.svg';
import _focusItoImg from '../../_metronic/assets/images/focus-ito.svg';

export const focusItoImg = _focusItoImg;
export const focusItoImgWhite = _focusItoImgWhite;
export const focusAuthImg1 = _focusAuthImg1;
export const focusAuthImg2 = _focusAuthImg2;
export const focusAuthImg3 = _focusAuthImg3;
export const focusAuthImg4 = _focusAuthImg4;
