import {FC} from 'react'
import moment from 'moment';

type Props = {
  datetime: any
}

const UserTimeLogDateTime: FC<Props> = ({ datetime }) => {
  
  return (
    <div className='d-flex flex-column custom-table-data'>
        {moment(datetime).utc().format('DD MMM YYYY - hh:mm A').toLowerCase()}
    </div>
  )
}

export {UserTimeLogDateTime}
