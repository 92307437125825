import {useState} from 'react';
import {ExpandableLabelProps} from './types';

const ExpandableLabel: React.FC<ExpandableLabelProps> = ({maxSize = 200, children}) => {
  const [size, setSize] = useState(maxSize);
  const [icon, setIcon] = useState('fa-ellipsis');

  const onExpand = () => {
    if (size === maxSize) {
      setSize(Infinity);
      setIcon('fa-chevron-up');
    } else {
      setSize(maxSize);
      setIcon('fa-ellipsis');
    }
  };
  return (
    <div className=''>
      {children?.toString().substring(0, size)}{' '}
      {maxSize < (children?.toString().length || 0) && (
        <span className='text-primary' role='button' onClick={onExpand}>
          (<i className={`fa-solid ${icon} text-primary`}></i>)
        </span>
      )}
    </div>
  );
};

export default ExpandableLabel;
