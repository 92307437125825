enum UserRole {
  Director = 'Director',
  Manager = 'Manager',
  Staff = 'Staff',
  public = 'public',
}

export const optionsRoles = {
  timelog: [UserRole.Staff],
  users: [UserRole.Manager, UserRole.Director],
  clientManagers: [UserRole.Manager, UserRole.Director],
};

export {};
