import {Outlet, Route, Routes} from 'react-router-dom';
import {ClientManagersListWrapper} from './client-managers-list';

const ClientManagersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='' element={<ClientManagersListWrapper />} />
      </Route>
    </Routes>
  );
};

export default ClientManagersPage;
