import * as Yup from 'yup'

export const initialValues = {
  password: '',
  repeatPassword: '',
}

export const dataSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,20}$/,
      'AUTH.CHANGE.LABEL.VALIDATION.PASSWORD'
    )
    .required('AUTH.VALIDATION.REQUIRED_FIELD'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), ""], 'AUTH.INPUT.REPEAT_PASSWORD.VALIDATION.MATCH')
    .required('AUTH.INPUT.REPEAT_PASSWORD.VALIDATION'),
});

