import {FC} from 'react'

type Props = {
  user: any
}

const ClientManagerInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column custom-table-data'>
      <div className='text-gray-800  mb-1'>
        {user.first_name} {user.last_name}
      </div>
      <span>{user.email}</span>
    </div>
  </div>
)

export {ClientManagerInfoCell}
