import {KTIcon} from '../../../../../../_metronic/helpers'
import {useTranslate} from '../../../../../function/translate'
import {useListView} from '../core/ListViewProvider'

const ClientManagerEditModalHeader = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const translate = useTranslate()

  return (
    <>
      <div
        className='modal-header'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 0,
          paddingBottom: '10px',
        }}
      >
        {/* begin::Modal title */}
        <div>
          {itemIdForUpdate ? (
            <div className='custom-title-add'>{translate('CLIENT_MANAGER.EDIT-USER')}</div>
          ) : (
            <div className='custom-title-add'>{translate('CLIENT_MANAGER.ADD-USER')}</div>
          )}
        </div>
        {/* end::Modal title */}

        {/* begin::Close */}

        <div>
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-users-modal-action='close'
            onClick={() => setItemIdForUpdate(undefined)}
            style={{cursor: 'pointer'}}
          >
            <KTIcon iconName='abstract-11' iconType='solid' className='fs-1' />
          </div>
          <div style={{height: '45px'}}></div>
        </div>

        {/* end::Close */}
      </div>
    </>
  )
}

export {ClientManagerEditModalHeader}
