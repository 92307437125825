import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  id?: ID
  name?: string
  email?: string
  department?: string
  role?: string
  first_name?: string
  last_name?: string
  departments?: string
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  name: '',
  email: '',
  department: '',
  role: ''
}

export type UserTimeLog = {
  id?: ID
  clockIn?: string,
  clockOut?: string
  user?: number,
  email: string
}

export type UserLogs = {
  id?: ID
  name?: string
  clockIn?: string
  clockOut?: string
  created_at?: string
}

export const initialUserLogs: UserLogs = {
  name: '',
  clockIn: '',
  clockOut: '',
  created_at: '',
}
export type UsersLogQueryResponse<T> = Response<Array<T>>
