import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ChangePasswordPage} from '../pages/change-password'
import {ProtectedRoute} from '../hooks/protectedRoute'
import {useAuth} from '../modules/auth'
import {UsersListWrapper} from '../modules/apps/user-management/users-list/UsersList'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import UsersPage from '../modules/apps/user-management/UsersPage'
import ClientManagersPage from '../modules/apps/client-managers/ClientManagersPage'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const {currentUser} = useAuth()
  const redirectionPath = currentUser?.role?.name === 'Director' || currentUser?.role?.name === 'Manager' ? '/users' : '/user-logs'
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={redirectionPath} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='user-logs' element={<DashboardWrapper />} />
        <Route path='change-password' element={<ChangePasswordPage />} />

        <Route path='userss' element={<ChangePasswordPage />} />

        <Route element={<ProtectedRoute userRole={currentUser?.role.name} module='users' />}>
          {/*<Route path='users' element={<UsersListWrapper />} />*/}
          <Route
            path='users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<ProtectedRoute userRole={currentUser?.role.name} module='clientManagers' />}>
          {/*<Route path='users' element={<UsersListWrapper />} />*/}
          <Route
            path='client-managers/*'
            element={
              <SuspensedView>
                <ClientManagersPage />
              </SuspensedView>
            }
          />
        </Route>

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
