/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {focusItoImg, focusItoImgWhite} from '../../constants/assets';
import './styles/authStyles.scss';

const AuthLayout = ({isClientFeedback = false}) => {
  const bgUrl = isClientFeedback ? '/media/misc/feedback.jpg' : '/media/misc/chronus.jpg';
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2 w-100'
        style={{backgroundImage: `url(${toAbsoluteUrl(bgUrl)})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center w-100'>
          {/* begin::Logo */}
          <img
            alt='Logo'
            src={isClientFeedback ? focusItoImg : focusItoImgWhite}
            className='h-75px position-absolute top-10'
          />
          {/* end::Logo */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
      <ToastContainer />
    </div>
  );
};

export {AuthLayout};
