import React from 'react';
import './CustomCss.css';
const CustomAlert = ({ errorMessage, onClose, className = 'mb-lg-15 alert alert-danger', classContent ='' }) => {
  return (
    <div className={classContent}>
      <div className= {className}>
      <div className='d-flex justify-content-between align-items-center '>
        <div className='alert-text font-weight-bold '>
        {Array.isArray(errorMessage) ? errorMessage.map((errorMessages, index) => (
              <div key={index}>{errorMessages}</div>
            )): errorMessage}
        </div>
        <button type='button' className='btn-close' onClick={onClose} aria-label='Close'></button>
      </div>
    </div>
    </div>
    
  );
};

export default CustomAlert;