// @ts-nocheck
import {Column} from 'react-table';
import {formatPhoneNumber} from '../../../../../../function/generalHelper';
import {ClientManager} from '../../core/_models';
import {ClientManagerActionsCell} from './ClientManagerActionsCell';
import {ClientManagerCustomHeader} from './ClientManagerCustomHeader';
import {ClientManagerDepartment} from './ClientManagerDepartment';
import {ClientManagerInfoCell} from './ClientManagerInfoCell';

const clientManagersColumns: ReadonlyArray<Column<ClientManager>> = [
  {
    Header: (props) => (
      <ClientManagerCustomHeader
        tableProps={props}
        title='DASHBOARD-ADMIN-NAME'
        className='w-300px custom-header-title'
      />
    ),
    id: 'first_name',
    Cell: ({row}) => {
      return (
        <div>
          <ClientManagerInfoCell user={row.original} />
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <ClientManagerCustomHeader
        tableProps={props}
        title='DASHBOARD-ADMIN-DEPARTMENT'
        className='w-175px custom-header-title'
      />
    ),
    id: 'department_id',
    Cell: ({row}) => {
      return (
        <div>
          {row.original.department ? (
            <ClientManagerDepartment department={row.original.department?.parent || row.original.department} />
          ) : (
            <div style={{paddingLeft: '20px'}}>___</div>
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <ClientManagerCustomHeader
        tableProps={props}
        title='DASHBOARD-ADMIN-TEAM'
        className='w-175px custom-header-title'
      />
    ),
    id: 'no_sort_team',
    Cell: ({row}) => {
      return (
        <div>
          {row.original.first_name} {row.original.last_name}'s Team
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <ClientManagerCustomHeader
        tableProps={props}
        title='DASHBOARD-ADMIN-PHONE'
        className='w-175px custom-header-title'
      />
    ),
    id: 'no_sort_phone',
    Cell: ({row}) => {
      return <div>{formatPhoneNumber(row.original.phone)}</div>;
    },
  },
  {
    Header: (props) => (
      <ClientManagerCustomHeader
        tableProps={props}
        title='DASHBOARD-ADMIN-ACTIONS'
        className='text-end min-w-200px custom-header-title'
      />
    ),
    id: 'actions',
    Cell: ({row}) => <ClientManagerActionsCell id={row.original.id} />,
  },
];

export {clientManagersColumns};
