import moment from 'moment';
import * as Yup from 'yup';

export const initialValues = {
  created_at: new Date().toISOString(),
  name: '',
};

function parseDateTime(dateInput: moment.MomentInput, timeInput: string) {
  let dateTimeString: moment.MomentInput;

  if (dateInput instanceof Date) {
    dateTimeString = moment(dateInput).format('YYYY-MM-DD') + ' ' + timeInput;
  } else if (typeof dateInput === 'string') {
    dateTimeString = moment(dateInput, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' ' + timeInput;
  }

  return moment(dateTimeString, 'YYYY-MM-DD hh:mm A');
}

export const dataSchema = Yup.object().shape({
  created_at: Yup.date().required('AUTH.VALIDATION.REQUIRED_FIELD'),
  clockIn: Yup.string()
    .required('AUTH.VALIDATION.REQUIRED_FIELD')
    .test('is-clockIn-less-than-clockOut', 'TIME.VALIDATION.CLOCKIN', function (clockIn) {
      const {clockOut, clockInDate, clockOutDate} = this.parent;

      if (clockIn && clockOut) {
        const clockInMoment = parseDateTime(clockInDate, clockIn);
        const clockOutMoment = parseDateTime(clockOutDate, clockOut);

        return clockInMoment.isBefore(clockOutMoment);
      }
      return false;
    }),
  clockOut: Yup.string()
    .required('AUTH.VALIDATION.REQUIRED_FIELD')
    .test('is-clockOut-greater-than-clockIn', 'TIME.VALIDATION.CLOCKOUT', function (clockOut) {
      const {clockIn, clockInDate, clockOutDate} = this.parent;

      if (clockIn && clockOut) {
        const clockInMoment = parseDateTime(clockInDate, clockIn);
        const clockOutMoment = parseDateTime(clockOutDate, clockOut);

        return clockOutMoment.isAfter(clockInMoment);
      }
      return false;
    }),
});

export default dataSchema;
