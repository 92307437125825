import {FC} from 'react'

type Props = {
  department: any
}

const ClientManagerDepartment: FC<Props> = ({department}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column custom-table-data'>{department?.name}</div>
  </div>
)

export {ClientManagerDepartment}
